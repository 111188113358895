//
// STORE MTO.JS

import Vue from "vue";

export default { 
  namespaced: true,
  state() {
    return {
      api:'',
      tipo:'M',
      name:'',
      estado:'',
      schema:null,
      record:[],      
      recordsDetalle:[],     
      //id:0,
      //accion:'',  
      componenteTipo:'',
      padre:'',   
      hijo:''      
      //exec: false
      //btra:'M'
    }
  },


  getters: {
    stId: function(state) {
      return state.record? state.record.id : 0;
    },

    stEdicion: function(state) {
      return state.estado==='nuevo' || state.estado==='editar'? true : false;
    },
  },


  mutations: {    
    data2State(state, data) {
      Vue.set(state, data.prop, data.value);        
    },  

    get_recordUpdate(state, data) {
      // guardo el record de MTO en store      
      Vue.set(state, 'record', data.apiResult.r[0][0]);      
      Vue.set(state, 'recordsDetalle', data.apiResult.r); 
      
      // guardo record de Mto y estado en store
      if (data.estado) Vue.set(state, 'estado', data.estado);
    } 
  },


  actions: {

    // 
    nuevo(context) {
      context.dispatch('update_store', { record:{ id:0 }, estado:'nuevo'} );
    },


    //
    nuevoDetalle(context) {
      context.dispatch('update_store', { record:{ id:0 }, estado:'nuevo'} );
      
      var hijo= context.state.hijo;      
      if (!hijo) return;
      
      //
      var recordsDetalle= context.state.recordsDetalle;
      var indice= context.rootState[hijo].no_getRecordsAPI;
      recordsDetalle[indice]= [];
      
      context.commit('data2State', { prop:'recordsDetalle', value:recordsDetalle });
      context.commit(hijo + '/data2State', { prop:'records', value: context.state.recordsDetalle[indice] }, { root: true });
    },


    //
    async borrar(context, ctrls= {}) {
      // elimino el registro guardado en record (store)
      var args = { tipo: context.state.tipo, api: context.state.api, accion: 3, fn_args: { id: context.state.record.id , ...ctrls}};     
      var apiResult= await context.dispatch("ajaxRequest", { args: args }, {root:true});
     
      // actualizo linea del records padre
      if (context.state.padre && !apiResult.sql.error) {
        context.commit(context.state.padre + '/update_record', { opcion:'borrar', id:context.state.record.id}, { root: true })
      }

      // actualizo record Mto
      context.commit('data2State', { prop:'record', value:[] });
      return apiResult;
    },


    //
    async guardar(context, data) {           
      console.log("-------- Datos a guardar: ", data);
      
      var args= data.args? data.args : { tipo: context.state.tipo, api: context.state.api, accion: 4, fn_args: { id: context.state.record.id, estado: context.state.estado, changes: data.changes }};
     
      var apiResult= await context.dispatch("ajaxRequest", { args: args }, {root:true});          
      console.log("-------- Datos guardados: ", apiResult);
      
      // actualizo elemento del Grid con los datos del registro modificado
      if (context.state.padre && !apiResult.sql.error) {      
        context.commit(context.state.padre + '/update_record', { opcion:'guardar', record:apiResult.r[0][0], id:context.state.estado=='nuevo'? 0 : apiResult.r[0][0].id}, { root: true })
      }
     
      return apiResult;
    },
        

    //
    update_store(context, {record, estado}) {        
      context.commit('data2State', { prop:'record', value:record});      
      if (estado) context.commit('data2State', { prop:'estado', value:estado});
    },


    // 
    async get_record(context, data) {      
      console.log("*** get_record en mixinMto. ID: ", data, " ***");
     
      // si no recibo argumentos, monto los argumentos por defecto
      if (!data.args) {
        data.args = { tipo: context.state.tipo, api: context.state.api, accion: 0, fn_args: { id: data.id }};     
      }
      console.log('args GET_RECORD: ', data.args);
      // llamada a la API
      var apiResult= await context.dispatch("ajaxRequest", { args: data.args }, { root: true });       
      console.log('apiResult GET_RECORD: ', apiResult);
      // guardo todos los records del Mto en store_mto
      context.commit('data2State', { prop:'recordsDetalle', value:apiResult.r }); 

      // guardo record actual y estado del mto en store_mto
      context.dispatch('update_store', { record:apiResult.r[0][0], estado:data.estado});
      return apiResult;
    }
      
  },

  
};
