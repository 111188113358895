export var mixComponente = {  

  methods: {    

    // COMPONENTE PICK **********************************************************************************************************************************
    pickV1() {
      let self= this;

      return {
        // devuelvo datos a guardar        
        get({ ctrl, record_aux }) {          
          record_aux[ctrl[0]]= ctrl[2];
        },   
                
        // muestro datos
        async set({ctrl, record, sch}) {             
          // si no tiene f, salgo
          if (!ctrl[0]) return;          

          // si es un ini, actualizo valor del record con el dato del ini
          if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];
          
          // si el valor de ctrl en el record está vacío, inicializo datos y salgo
          if (record[ctrl[0]]=== "" || record[ctrl[0]]=="0") {            
            self.$set(ctrl, 2, "0");
            self.$set(ctrl[4].comp, 'valueAux', "");
            return;
          }
    
          // compruebo si existe el campo pick en el record
          // si existe, lo cojo, si no, ejecuto api      
          if (typeof record["name_" + ctrl[0]]!== "undefined") {
            console.log("Obtengo Pick " + ctrl[0] + ": "+record[ctrl[0]]+" del Record, NO de la API... ", record["name_" + ctrl[0]]);
            self.$set(ctrl, 2, record[ctrl[0]]);
            self.$set(ctrl[4].comp, 'valueAux', record["name_" + ctrl[0]]);
            return;
          }         
       
          // ejecuto api pick
          var args = { tipo: "comp", api: "pick", accion: "get", fn_args: {
              name: ctrl[4].comp.api,
              id: record[ctrl[0]],
              source: ctrl[4].comp.source? ctrl[4].comp.source : ""
            }
          };

          //   pendiente control de errores
          console.log('args pick: ', args);
          var apiResult = await self.$store.dispatch("ajaxRequest", { args: args });
          console.log('apiResult pick: ', apiResult);
          // no he recibido datos
          if (apiResult.sql.error) {
            self.$set(ctrl, 2, "0");
            self.$set(ctrl[4].comp, 'valueAux', "(error " + record[ctrl[0]] + " no encontrado)");
            return;
          }
    
          // he recibido datos          
          self.$set(ctrl, 2, record[ctrl[0]]);
          self.$set(ctrl[4].comp, 'valueAux', apiResult.r[0][0][ctrl[4].comp.fAux]);      
          
          // si hemos definido una fn en el schema, la ejecuto pasándole el row
          //if (ctrl.fn) this[ctrl.fn](apiResult.r[0][0]);
        }

      };    
     
    },


    // COMPONENTE PICK MULTIPLE *************************************************************************************************************************
    pickMultipleV1() {
      let self= this;

      return {       
        //
        get({ ctrl, record_aux }) {
          record_aux[ctrl[0]]= ctrl[2];
        },   

        //
        async set({ ctrl, record, sch, forzar=false }) {          
          // si no tiene field, salgo
          if (!ctrl[0]) return; 
          
          // si es un ini, actualizo valor del record con el dato del ini          
          if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];
                    
          // si el valor de ctrl en el record está vacío, inicializo datos y salgo
          if (record[ctrl[0]]== "" || record[ctrl[0]]== "0") {            
            self.$set(ctrl, 2, "0");
            self.$set(ctrl[4].comp, 'valueAux', "");
            return;
          }

          // compruebo si existe el campo pick en el record
          // si existe, lo cojo, si no, ejecuto api      
          if (!forzar && typeof record["name_" + ctrl[0]]!== "undefined") {
            console.log("Obtengo Pick Multiple " + ctrl[0] + ": " + record[ctrl[0]] + " del Record, NO de la API... ", record["name_" + ctrl[0]]);
           
            self.$set(ctrl, 2, record[ctrl[0]]);
            self.$set(ctrl[4].comp, 'valueAux', record["name_" + ctrl[0]]);
            return;
          }         
          
          // obtengo array de datos y filtro por el valor del ctrl asociado          
          var A_elems = ctrl[4].comp.source? ctrl[4].comp.source : [];
          var elem = A_elems.filter(e => e.id === record[ctrl[4].comp.f2]);
          if (elem.length <= 0) {           
            self.$set(ctrl, 2, "0");
            self.$set(ctrl[4].comp, 'valueAux', "");
            return;
          }
          
          // llamada a la API
          var args = {
            tipo: "comp",
            api: "pick",
            accion: "get",
            fn_args: { name: ctrl[4].comp.api, id: record[ctrl[0]], tabla: elem[0].tabla }
          };
       
          var apiResult = await self.$store.dispatch("ajaxRequest", { args: args });      
          if (apiResult.sql.error) {            
            self.$set(ctrl, 2, "0");
            self.$set(ctrl[4].comp, 'valueAux', "(error " + record[ctrl[0]] + " no encontrado)");
            return;
          }
         
          // he recibido datos
          self.$set(ctrl, 2, record[ctrl[0]]);
          self.$set(ctrl[4].comp, 'valueAux', apiResult.r[0][0][ctrl[4].comp.fAux]);
          self.$set(ctrl[4].comp, 'finder', elem[0].finder);
          return apiResult.r[0];
        },
    
      };    
     
    },


    // COMPONENTE FECHA *********************************************************************************************************************************
    fecha() {
      let self= this;

      return { 
        //     
        get({ ctrl, record_aux }) {         
          if (ctrl[2]== "") {
            record_aux[ctrl[0]]= "";
            return;
          }
                    
          record_aux[ctrl[0]]= ctrl[2];
        },   
        
        //
        set({ctrl, record, sch}) {          
          if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];
                    
          if (record[ctrl[0]]!= null && record[ctrl[0]]!= undefined) { 
            if (record[ctrl[0]].includes('0000')) {           
              self.$set(ctrl, 2, "");
              record[ctrl[0]]= "";              
              return;
            }
          } 
                    
          self.$set(ctrl, 2, record[ctrl[0]]== null? "" : record[ctrl[0]]);          
        }        

      }
    },


    // COMPONENTE GEOLOCALIZACIÓN ***********************************************************************************************************************
    geo() {
      let self= this;

      return { 
        //     
        get({ ctrl, record_aux }) {
          record_aux[ctrl[0]]= ctrl[2];
        },   
        
        //
        set({ctrl, record, sch}) {            
          if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];          
          self.$set(ctrl, 2, record[ctrl[0]]);
        }       

      }
    },


    // COMPONENTE OBSERVACIONES *************************************************************************************************************************
    obs() {
      let self= this;

      return {   
        //  
        get({ ctrl, record_aux }) {
          record_aux[ctrl[0]]= ctrl[2];
          //record[schema.f]== schema.comp.value + '\n' + schema.value;
        },   
        
        //
        set({ctrl, record, sch}) {            
          if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];
          self.$set(ctrl, 2, record[ctrl[0]]);
        }      

      }
    },


    // COMPONENTE OBSERVACIONES 3 ***********************************************************************************************************************
    obs3() {
      let self= this;
      /* let fieldAnterior='';
      let A_datos=[]; */
      
      return {  
        //   
        // get({ ctrl, schema, record_aux }) { 
        get({ ctrl, record, record_aux }) {
         
          let texto='';
          self.$set(ctrl, 2, '');

          // obtengo el f donde guardar datos según el ROL   
          let field= ctrl[4].comp.fields[self.$store.state.G.ROL];

          // obs3 con opciones de motivos tiene data como propiedad 
          if (ctrl[4].comp.data) {          
            texto = ctrl[4].comp.data.texto;
            if (texto != null) texto.trim();
            if (texto == '') return;
            texto = texto.slice().replace(/\[/g, "(");
            texto = texto.replace(/\]/g, ")");
            let encabeza = ctrl[4].comp.data.encabeza;
            if (encabeza != '') encabeza += '\n';
            record_aux[field] = self.identificacion() + " " + encabeza + texto + "\n" + record[field];
            // para guardar en avisos le quito la cabecera y guardo el valor en ctrl.value;
            self.$set(ctrl, 2, self.identificacion() + " " + texto + "\n");
            return
          }
          
          // obs3 sin motivos
          // compruebo si hay valor a guardar en valueAux1
          record_aux[field]= record[field];
          if (ctrl[4].comp.valueAux1) {
            record_aux[field]= self.identificacion() + " " + ctrl[4].comp.valueAux1 +"\n"+ (record_aux[field]? record_aux[field] : '');            
          }

          // compruebo si hay valor a guardar en valueAux
          texto = ctrl[4].comp.valueAux;
          if (texto != null) texto.trim();
          if (texto == '') return;
  
          texto = texto.slice().replace(/\[/g, "(");
          texto = texto.replace(/\]/g, ")");
          record_aux[field] = self.identificacion() + " " + texto + "\n" + (record_aux[field]? record_aux[field] : '');
        },

       

        set({ ctrl }) {          
          let field = ctrl[4].comp.fields[self.$store.state.G.ROL];
          self.$set(ctrl[4].comp, 'valueAux', '');
          self.$set(ctrl, 2, '');
  
          // obs3 con motivos
          if (ctrl[4].comp.data) {
            self.$set(ctrl[4].comp, 'data', { texto: '', encabeza: '', select1: 0, select2: 0 });
            return;
          }

          // obs3 con texto
          for (field in ctrl[4].comp.fields) {
            self.$set(ctrl, 2, (ctrl[2] + ctrl[4].comp.fields[field]));
          }
          /* A_datos=[];
          self.$set(ctrl[4].comp, 'valueAux', "");
          self.$set(ctrl[4].comp, 'valueAux1', "");
          let fields= ctrl[4].comp.fields;
          fieldAnterior='';

          //Añado propiedad al schema del control para dividir por rol co formato
          ctrl[4].comp.roles={gestion:[], apds:[], jz:[]}
          ctrl[4].comp.rolesTitulo={gestion:'CTD', apds:'APD', jz:'Jefe de Zona'}
          
          // Recopilo datos
          for (const rol in fields){
              let rolField=fields[rol]
              this.recopila_fields(ctrl,schema,record,rol,rolField)
          }

          // Orden descendente
          A_datos.sort((x, y) => y.orden - x.orden);

          // orientacion y no repertir nombres
          let nameAnterior=''
          let orient='right'
          A_datos.forEach(item=>{
            if (nameAnterior==item.name) {
                item.nameVista=''
                item.orient=orient

            } else {
              nameAnterior=item.name
              item.nameVista=item.name
              orient=orient=='right'?'left':'right'
              item.orient=orient
            }

          })
          
          self.$set(ctrl, 2, A_datos); */
                  
          //
          // set({ctrl, schema, record, ini}) {
          // // campo que se muestra
          // ctrl.comp.valueaux= "";   

          // var fields= ctrl.comp.fields; 
          // // paso los datos del record a todos los campos de 'fields'                   
          // for (const field in fields) {
          //   if (ini) record[fields[field]]= typeof schema[fields[field]].ini=== "undefined"? "" : schema[fields[field]].ini;
          //   schema[fields[field]].value= record[fields[field]];         
          // }          
         
        },  
        
        /* recopila_fields(ctrl,schema,record,rol,rolField){

          if (fieldAnterior==rolField)       return;

          if (!record[rolField])             return;          
          if (record[rolField]=='')          return;

          fieldAnterior=rolField;
          let ar = record[rolField].split("[");
          if (ar) ar.shift();


          if (ar) {
            ar.forEach(item => {
              // los item quedan asi: item=Lourdes P 18:18 14-6-2017] ** POST-CIERRE: Expediente de prueba, se procede a anular
              let A_idTexto = item.split("]");            
              let texto = A_idTexto[1];
              let dia, hora, name, pos;

             
              // conpruebo si la identificación es correcta
              // formato. usu hora fecha
              if (A_idTexto[0].indexOf(":")< 0 || A_idTexto[0].indexOf("-")< 0) {                
                A_idTexto[0]= "Sin Iden. 00:00 00-00-0000";
              }
  
              // get dia
              pos = A_idTexto[0].lastIndexOf(" ");
              dia = A_idTexto[0].slice(pos + 1);
  
              // elimino dia y me quedo con name y hora
              A_idTexto[0] = A_idTexto[0].slice(0, pos);
  
              // saco hora
              pos = A_idTexto[0].lastIndexOf(" ");
              hora = A_idTexto[0].slice(pos + 1);
              let A_hora = hora.split(":");              
              hora= A_hora[0].padStart(2, "0").concat(':', A_hora[1].padStart(2, "0"));
  
              // elimino hora y me queda name
              A_idTexto[0] = A_idTexto[0].slice(0, pos);
              name = A_idTexto[0].slice(0, pos);
              // en A_idTexto[0] me queda el nombre              
              let A_dia = dia.split("-");                     
              let fecha_date = new Date(
                A_dia[2],
                A_dia[1] - 1,
                A_dia[0],
                A_hora[0],
                A_hora[1],
                0,
                0,
                0
              );
             
              A_datos.push({
                dia: dia,
                hora: hora,
                name: name,
                texto: texto,
                // para oredenar ascendente o descendente
                orden: fecha_date.getTime(),
                color: rol
              });

              // para division por rol acumulo items con formato
              try {
                ctrl.comp.roles[rol].push({
                  dia: dia,
                  hora: hora,
                  name: name,
                  texto: texto,
                  color:rol
                })
              } catch{
                console.log("obs3 error en rol")
              }
              
            });
          }

        } */
        
      }

    },


    // COMPONENTE CONTROL DE CAMBIOS ***********************************************************************************************************************
    tcc() {
      let self= this;

      return {     
        get({ ctrl, record_aux }) {
          record_aux[ctrl[0]]=ctrl[4].comp.valueAux+ctrl[2];
        },

        //        
        set({ctrl, record, sch}) {          
          if (sch) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];          
          self.$set(ctrl[4].comp, 'valueAux', "");
          self.$set(ctrl, 2, record[ctrl[0]]);
        }     
      }

    },


    // COMPONENTE IMAGEN ***********************************************************************************************************************
    img() {
      let self= this;

      return {     
        get({ ctrl, record_aux }) {
          if (!ctrl[0]) return;    
          //console.log('FILES: ', ctrl.comp.files);            
          record_aux[ctrl[0]]= ctrl[4].comp.files;  
        },

        //
        async set({ctrl, record, sch}) { 
          // si tiene una url inicial, la guardo y salgo 
          if (sch) {            
            console.log('set IMG: ', ctrl, record, sch);
            if ( sch[ctrl[0]]) record[ctrl[0]]= typeof ctrl[2]=== "undefined"? "" : sch[ctrl[0]][2];
            return;
          }
          
                    
          // lamada API para obtener la url de la imagen
          let get = ['', 'imagenDB', { fnt:'get', tip:0, tip_id:0 }, 'aplica'];
          let param= { apiArg:get, args:{ tip:ctrl[4].comp.tip, tip_id:record.id }};
          let apiResult = await self.$store.dispatch("apiCall", param);
          console.log('set comp IMG: ', apiResult);
          
          
          // si no tiene imagen blanqueo datos
          if (apiResult.sql.error||(!apiResult.sql.error && apiResult.r[0]== '')) {
            self.$set(ctrl, 2, "");
            return;
          }
          
          // guardo url
          self.$set(ctrl, 2, self.$store.state.G.url_tmpNV + apiResult.r[0]);
        }     
      }

    }
   
  }
};
