//
// STORE_GLOBAL.JS

export default {
  namespaced: true,
  state: {
    urladm: "",
    enrutadorURL: "",
    url_tmp: "",
    url_tmpNV: "",
    //
    infadm: "",
    informesURL: "",
    inf_tmp: "",

    // VARIABLE AÑADIDA PARA SELECCIONAR EL ENRUTADOR
    // 0: versión antigua,
    // 1: version nueva de APLICA

    vs:0,

    PRODUCTION: true,

    APP: "1",
    APPNAME: "",
    VERSION: "1.01",
    ROL:null,
    ROLID:null,
    USUARIO: null,
    USUDAT: {},
    ZONA:"0"

  },

  mutations: {
    set_URLS(state) {
      let prefijo= state.PRODUCTION ? '' : 'pruebas.';

      let domain='iberopa.com';

      //enrutador
      state.urladm= "https://" + prefijo + domain + "/";

      state.enrutadorURL= state.urladm + "abk/exec.php";
      state.url_tmp= state.urladm + "abk/tmp/";

      //informes
      state.infadm= state.urladm;
      //state.informesURL= state.infadm + "abk_141220/vue/enrutador.php";
      state.inf_tmp= state.infadm + "tmp/";

      //
      state.informesURL= state.infadm + "abk/exec.php";
      state.inf_tmp= state.infadm + "abk/tmp/";

    },

    set_URLSNV(state) {
      state.url_tmpNV= state.urladm + "abk/tmp/";
      state.inf_tmpNV= state.urladm + "tmp/";
    }
  },

  actions: {}
};
