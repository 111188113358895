<template>

<div>
 

  <!-- AVISO 1 -->
  <v-btn
    v-if="$store.state.login_estado!=null && $store.state.G.USUDAT.aviso1"
    style="margin-left: 40px"
    @click="$emit('aviso', { accion: 'avisosExpedientes' })"
    icon
    x-small
  >
    <v-badge
      color="white red--text"
      overlap
      title="Avisos incidencias"
      offset-x="3"
      offset-y="10"
      :content="aviso1n"
    >
      <v-icon icon color="#FF4500">{{ "mdi-bell" }}</v-icon>
    </v-badge>
  </v-btn>



   <!-- AVISO 2 -->
  <v-btn
   v-if="$store.state.login_estado!=null && $store.state.G.USUDAT.aviso2"
    style="margin-left: 40px"
    @click="$emit('aviso', { accion: 'avisosFacturas' })"
    icon
    x-small
  >
    <v-badge
      color="white green--text"
      overlap
      title="Avisos Facturación"
      offset-x="3"
      offset-y="10"
      :content="aviso2n"
    >
      <v-icon icon color="green">{{ "mdi-bell" }}</v-icon>
    </v-badge>
  </v-btn>
</div>
</template>

<script>
import { mixinAvisos } from "@/mixins/mixinAvisos.js";
export default {
  mixins: [mixinAvisos],


  data() {
    
    return {
       timer:'',
       aviso1n:0,
       aviso2n:0

    };
  },

  created() {
    this.recuperaN();
    this.ini_data();
      
  },


  methods:{
    async ini_data() {
      this.timer= setInterval(this.recuperaN, 200000);    
    },

    async recuperaN(){
      console.log("recuperaN:",this.$store.state.login_estado)
      if (!this.$store.state.G.USUDAT.aviso1 && !this.$store.state.G.USUDAT.aviso2) return;

      if (this.$store.state.login_estado==null) {
        this.aviso1n=0
        this.aviso2n=0
        return;
      } 

      let avisosN=[0,0]; //await this.avisoGet('num');
      console.log("avisosN:",avisosN)
      this.aviso1n=avisosN[0]
      this.aviso2n=avisosN[1]
    }
  }
 
};
</script>


