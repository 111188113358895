
<template>
    <div class="commonF" v-if="modal">


        <div v-if="tipoView!='FCA' || !viewMtoOnly">
            <v-dialog
                v-model="modal"
                content-class="modal"
                persistent>
                    <template>

                        <keep-alive>

                            <component
                                :is="compName"
                                sync
                                :syncAccion="syncAccion"
                                :storeRaiz="raiz"
                                :masterStore="raiz"
                                :masterRecordField="masterRecordField"
                                :tipoView="tipoView"
                                :mView="mView"
                                :ctrlCt="ctrlCt"
                                readOnly
                                :viewMtoOnly="viewMtoOnly"
                                @onEvent="eventF">
                            </component>

                        </keep-alive>

                    </template>
            </v-dialog>
        </div>

        <div v-else>
            <keep-alive>

                <component
                    :is="compName"
                    sync
                    :syncAccion="syncAccion"
                    :storeRaiz="raiz"
                    :masterStore="raiz"
                    :masterRecordField="masterRecordField"
                    :tipoView="tipoView"
                    :mView="mView"
                    :ctrlCt="ctrlCt"
                    readOnly
                    :viewMtoOnly="viewMtoOnly"
                    @onEvent="eventF">
                </component>

            </keep-alive>
        </div>

    </div>
</template>



<script>

    import { mixCF } from "@/mixins/mixCF";

    export default {
        mixins: [mixCF],

        methods: {
            // ct, compName, fn (funcion a ejecutar al extraer)
            // método a ejecutar para mostrar un ctrlF / Mto
            // data: { ct, componenteName, fn= null, accion, store, recordField }
            openFinder(data) {
                console.log("DEV open ********** COMMONF: ", data);

                this.viewMtoOnly= false;
                this.show(data);
            },


            openMto(data) {
                console.log("DEV openMto ********** COMMONF: ", data);

                this.viewMtoOnly= true;
                this.show(data);
            },

        }
    };
</script>
