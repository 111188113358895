const control_style = {
  //
  install(Vue) {
    // control textfield
    (Vue.prototype.$input = {
      rounded: true,
      filled: true,
      dense: true
    }),

      // control textarea
      (Vue.prototype.$textarea = {
        rounded: true,
        filled: true,
        dense: true
      }),

      // control button
      (Vue.prototype.$btn = {
        fab: true,        
        small: true,
        color: "red"
      }),

      // control checkbox
      (Vue.prototype.$checkbox = {        
    
        dense: true,
        'false-value': "0",
        'true-value': "1"
      }),
      
      // control select
      (Vue.prototype.$select = {
        rounded: true,
        filled: true,
        dense: true
      });
  }
};

export default control_style;
