<!-- App.vue -->

<template>
  <v-app>
    <!-- Toolbar -->
    <AppBar @aviso="component_avisos=$event.accion"/>

    <!-- <v-content class="app-content" v-if="$store.state.datos_iniciales"> -->
    <!-- Vista -->
    <!-- <v-content class="app-content" v-if="$store.state.datos_iniciales"> -->
    <!-- <v-content class="app-content" v-if="$store.state.datos_iniciales"> -->
    <!-- <v-content class="app-content" v-if="$store.state.datos_iniciales"> -->
    <v-main :class="!$store.state.G.PRODUCTION? 'pruebas' : 'fondo'">
      <AppVista v-if="isLogued" />
    </v-main>
    <v-footer app absolute height="1px"> </v-footer>
    <!-- </v-content> -->
    <!-- </v-content> -->
    <!-- </v-content> -->
    <!-- Pie -->
    <!-- </v-main>  -->
    <!-- <AppFooter /> -->

    <!-- GLOBALES -->
    <alert ref="alert"></alert>
    <loading ref="loading"></loading>
    <common_finder ref="common_finder"></common_finder>
    <commonF ref="commonF"></commonF>

    <!-- Mto como ventana modal -->
    <div class="centrado">
      <component
          :is="component_avisos"
          @aviso="component_avisos=null">
      </component>
    </div>

  </v-app>
</template>



<script>

  import plugs from "@/common/general_plugs";
  const AppBar = () => plugs.groute("AppBar.vue", "comp");
  const AppVista = () => plugs.groute("AppVista.vue", "comp");
  const avisosExpedientes = () => plugs.groute("avisosExpedientes.vue", "comp");
  const avisosFacturas = () => plugs.groute("avisosFacturas.vue", "comp");
  /* const AppFooter = () => plugs.groute("AppFooter.vue", "comp"); */

  import alert from "@/components/alert";
  import loading from "@/components/loading";
  import common_finder from "@/components/common_finder";
  import commonF from "@/components/commonF";
  import avisos_campana from "@/components/avisos_campana";

  export default {
    components: { AppBar, AppVista, alert, loading, common_finder, commonF, avisos_campana, avisosExpedientes, avisosFacturas },
    name: "App",

    data() {
      return {
        title: 'APLICA2 - cemo',
        isLogued:false,
        component_avisos:null
      }
    },


    async created() {
      document.title=this.title;

      // establezo las urls
      this.$store.commit('G/set_URLS');

      // establezo las urls de la NUEVA VERSIÓN
      this.$store.commit('G/set_URLSNV');

      // compruebo si el usuario YA está logueado
      var logued= await this.$store.dispatch("isLogued");

      if (logued) {
        // cargo datos iniciales
        var apiResult= await this.$store.dispatch("ini/load_dataIni");
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          this.showLogin();
          return;
        }

        this.isLogued= true;

        // obtengo los avisos de expedientes
        //if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();

        return;
      }

      this.showLogin();
    },


    methods: {
      showLogin() {
        this.isLogued= true;
        if (this.$router.currentRoute.name!== 'login') {
          this.$router.push({ path:'login' });
        }
      }
    },


    mounted() {
      this.$root.$alert = this.$refs.alert;
      this.$root.$loading = this.$refs.loading;
      this.$root.$common_finder = this.$refs.common_finder;
      this.$root.$commonF = this.$refs.commonF;
    }
  };
</script>


<style lang="scss">
// .v-dialog__content {
//   align-items: center;
//   justify-content: center;
// }
.v-application {
  // This is where we'll add all the md-typography classes
  font-size: 12px;
  font-weight: 300;
}
.btras{
  padding-top: 4px;
  padding-bottom: 8px;
  display:flex;
  justify-content: flex-start;
}
.centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.conflex {
  display: flex;
  flex-wrap: wrap;
  /* background-color:grey; */
}
.conflexnow {
  display: flex;
  flex-wrap: nowrap;
  /* background-color:grey; */
}
.contenedor {
  padding: 10px;
  background-color: #eee;
  /* background-color:rgb(245, 219, 219); */
}
.fila {
  display: flex;
  flex-wrap: wrap;
}
.columna {
  display: flex;
  flex-direction: column;
}
.inline {
  display: inline;
}
.cab {
  width: 175px;
  text-align: center;
  color: white;
  background-color: #90a4ae;
  border-radius: 0 15px 0 0;
  height: 15px;
}
// tablas
.v-data-table-header th {
  white-space: nowrap;
}
.v-data-table td,
.v-data-table th {
  padding: 0 8px;
}
//
.theme--light.v-application {
  background-color:rgb(210, 230, 230);
}
.theme--light .v-content {
  background-color: #ddd;
}

.v-label {
  font-size: 1rem;
}

.v-label--active {
  font-size: 17px;
  color: steelblue !important;
}

.v-tab--active {
  color: red !important;
  font-weight: bold;
}

.v-input {
  font-size: 1rem;
}

.v-bottom-navigation {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.v-input--checkbox {
  margin-top: 0px;
  padding-top: 0px;
}

.modal {
  box-shadow: none !important;
  width: auto;
  max-width: 90% !important;
}

.modal70 {
  box-shadow: none !important;
  width: auto;
  width: 70% !important;
}

.tx {
  padding: 4px 8px;
  margin-top: 2px;
  border: none;
  border-radius: 18px;
  background-color: rgba(216, 234, 248, 0.45);
}

.theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  //color:rgb(14, 87, 116);
  color:darkblue !important;
}

.v-select__selection--disabled {
  //color:rgb(14, 87, 116) !important;
  color:darkblue !important;
}

.infostore table {
  margin-right: 5px;
  border: 1px solid grey;
  background-color: white;
}

.infostore table caption {
  background-color: #96ad97;
  color: white;
}

///////////////////////////////////////////////////////////////////////////////////////////////

// font menu y cargando

.v-treeview-node__label, .loading {
  font-size: 13px;
  font-weight: bold;

}

//fondo produccion y pruebas
.fondo {
  background-color:rgb(220, 220, 240);
}

.pruebas {
  background-color: rgb(241, 221, 200);
}

//color cabeceras
.cabecera {
  //indigo lighten-4
  background-color: "#c5cae9";
  color: blue;
}




</style>