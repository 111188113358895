//
// STORE_MENU.JS

export default {
  namespaced: true,
  state: {
    menu: [] // array de menú
  },

  getters: {},

  mutations: {},

  actions: {}
};
