//
// STORE FINDER.JS

import Vue from "vue";

export default {
  namespaced: true,
  state:() => {
    return {
      api:'',
      tipo:'F',
      name:'',
      //estado:'',
      schema:null,
      records:[],
      read_records:[],
      recordsDetalle:[],
      //id:0,
      //accion:'',
      //accionRow:{},
      rowSelected:[],
      //selected_row:[],
      //row_inEdition:null,      
      componenteTipo:'',
      padre:'',   
      hijo:'',
      hijoChanges:false,
      no_getRecordsAPI:0     
      //exec: false
      //btra:'F'
    }
  },

  getters: {
    stId: function(state) {
      return state.accionRow? state.accionRow.id : -1
    },    


  },

  mutations: {
    data2State(state, data) {
      if (data.key) {
        Vue.set(state[data.prop], data.key, data.value);
        return;
      } 

      Vue.set(state, data.prop, data.value);        
    }, 


    //
    initializate(state) { 
      
      if (state.hijo) this.$store.commit(this.$store.state[this.stName].hijo + '/data2State', { prop:'estado', value:'ver'}); 
    },


    // actualizo el record del Grid por recibido
    update_records(state, record) {
      Vue.set(state, 'records', record);
      Vue.set(state, 'read_records', JSON.parse(JSON.stringify(record)));
      Vue.set(state, 'rowSelected', []);
      Vue.set(state, 'hijoChanges', false);
    },


    // acciones sobre el item de records con el id recibido
    // acciones: nuevo, guardar y borrar
    update_record(state, { opcion, record, id }) {       
      if (opcion== 'nuevo') {
        if (!state.records) state.records= [];
        
        state.records.unshift({ id:id });   
        state.rowSelected= [{ id:id }];
        return;
      }

      // obtengo la posición del elemento en el array
      console.log('id a buscar: ', id);
      var index= id, index_rr= id;
      if (index!= 0) {
        index= state.records.findIndex(elem => elem.id == id);
        index_rr= state.read_records.findIndex(elem => elem.id == id);
      }
      
      switch (opcion) {      
      case 'guardar':        
        Vue.set(state.records, index, record);
        Vue.set(state.read_records, index_rr, record);
        Vue.set(state, 'rowSelected', []);
        state.accionRow= record;        
        break;

      case 'borrar':
        state.records.splice(index, 1);
        state.read_records.splice(index_rr, 1);
        Vue.set(state, 'rowSelected', []);
        state.accionRow= [];
        break;
      }
    },

  },

  actions: {

    // obtengo registros del Finder
    async get_records(context, data) 
    {
      console.log("DEV " + context.state.api + " get_records", data);  

      // compruebo si hemos definido que el grid coja el record directamente del padre
      if (context.state.no_getRecordsAPI> 0) {
        console.log('Cojo record de recordDetalle, index: ', context.state.no_getRecordsAPI);
        context.commit('update_records', context.rootState[context.state.padre].recordsDetalle[context.state.no_getRecordsAPI]);
        return;
      }
      
      // llamada a la API
      var args = { tipo: context.state.tipo, api:context.state.api, accion: data.accion, fn_args: { ctrls:data.ctrls }};      
      console.log("DEV " + context.state.api + " get_records argumentos: ", args);
      var apiResult = await context.dispatch("ajaxRequest", { args: args }, { root: true });
      console.log("DEV " + context.state.api + " resultado get_records", apiResult);
            
      // guardo records del Grid todos los arrays recibidos, en 'recordsDetalle'
      context.commit('update_records', apiResult.r[0]);
      context.commit('data2State', { prop:'recordsDetalle', value: apiResult.r });      
    },  





  }
};
