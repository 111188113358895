//
// STORE.JS

import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

import store_ini from "@/stores/modulos/store_ini";
import store_global from "@/stores/modulos/store_global";
import store_menu from "@/stores/modulos/store_menu";

import router from "@/common/router"; // plugin router

import store_finder from "@/stores/modulos/store_finder";
import store_mto from "@/stores/modulos/store_mto";
Vue.use(Vuex);


export default new Vuex.Store({
    modules: {
        ini: store_ini,
        G: store_global,
        menu: store_menu,

        F:store_finder,
        M:store_mto
    },

    state: {
        ac_conexiones: 0,
        A_menu: null,
        datos_iniciales: null,
        schemas: {
            F: {},
            M: {},
            R: {},
            docs: {},
            fras: {}
        },
        schemas_empty: {
            F: {},
            M: {},
            R: {},
            docs: {},
            fras: {}
        },

        // fechas
        separador_fecha: "/",

        record: null,

        // toolbar
        drawer: null,

        // login
        login_estado: sessionStorage.getItem("login") || null,

        // menú
        activeMenu:[],

        // alert
        alertState:false,
        msg_alertState:'',

        //fras_exp_detalle
        reload_fras_exp_detalle:false,

        ctrlFPreserve:[],

    },

    getters: {
        get_schemas: state => (tipo, api) => {
            return state.schemas[tipo][api];
        },

        get_dato_inicial: state => name => {
            return state.datos_iniciales[name];
        },

        get_activeMenu: state => {
            return state.activeMenu;
        },

        get_provZona: state=> origen => {
            let item_zona= state.datos_iniciales.provincias.find(elem => elem.loc== origen.substr(0, 8));
            if (item_zona) return item_zona.zona;
            return "0";
        },


        // NUEVA VERSION
        pesoGet: state => modulo => {

            if (typeof modulo=== 'object') return { permisoMto:[], permisoAccion:[] };
            if (typeof state.G.PESO[modulo]=== 'undefined') {
                // pendient de ver valor en error
                alert ("error busqueda permiso: "+modulo);
                return { permisoMto:[], permisoAccion:[] };
            }

            return state.G.PESO[modulo];
        },


        isLogued: state => {
            return state.login_estado;

        },

        ctrlFGet: state=> (modulo) => {
            if (!modulo || !state.ctrlFPreserve[modulo]) return '';
            return state.ctrlFPreserve[modulo];
        }

    },

    mutations: {
        update_datosIniciales(state, datos_iniciales) {
            // 0: datos iniciales, 1: menú, 2: datos globales (actualizo propiedades)
            state.datos_iniciales = datos_iniciales[0];
            state.A_menu = datos_iniciales[1];
            Object.keys(datos_iniciales[2]).forEach(key => {
                state.G[key.toUpperCase()]= datos_iniciales[2][key];
            });
        },

        data2State(state, data) {
            //Vue.set(state[data.modulo], data.prop, data.value);
            state[data.modulo][data.prop]= data.value;
        },

        set_schemas(state, data) {
            state.schemas[data.tipo][data.prop] = data.value;
        },

        // toolbar
        setDrawer: (state, value) => (state.drawer = value),

        // *********************************************************************************** login
        set_authSuccess: (state, token) => {
            sessionStorage.setItem("login", token);
            state.login_estado= sessionStorage.getItem("login");
            state.datos_iniciales= {};
        },

        set_activeMenu: (state, value) => (state.activeMenu = value),

        logout: state => {
            sessionStorage.removeItem("login");
            state.datos_iniciales= {};
            state.schemas= JSON.parse(JSON.stringify(state.schemas_empty));
            state.login_estado= null;
            state.A_menu= null;
            state.G.USUARIO=null;  state.G.USUDAT={};
        },


        // NUEVA VERSION
        SCHSET(state, data) {
            Vue.set(state.schemas, data.prop, data.value);
        },


        //
        ctrlFSet(state, { modulo='', valor='' }) {
            state.ctrlFPreserve[modulo]= valor;
        },

    },

    actions: {
        async isLogued0(context) {
            var args = {
                tipo: "login",
                api: "login",
                accion: "isAuthenticated",
                fn_args: { token: sessionStorage.getItem("login") || null }
            };

            // compruebo si el usuario está logueado y lo devuelvo
            var apiResult = await context.dispatch("ajaxRequest", { args });
            return apiResult? true : false;
        },

        async isLogued(context) {
            let apiArg= ["", "isAuthenticated", { token: sessionStorage.getItem("login") || null  }, 'login'];
            let param= { apiArg };
            let apiResult = await context.dispatch("apiCall", param);

            console.log('********************', sessionStorage.getItem("login"), apiResult);

            return !apiResult.sql.error;
        },


        // obtengo datos de la API
        async ajaxRequest(context, datos) {

            // añadidor servidor (1=informes)
            let srv=datos.srv;
            // añado el token a todas las llamadas a la API
            datos.args.token= sessionStorage.getItem("login") || null;
            datos.args.version= context.state.G.VERSION;

            // versión del enrutador a utilizar en el servidor (0: antigua version (por defecto), 1: nueva version (GESTION))
            // si no se recibe la versión del enrutador, añado la versión por defecto
            if (typeof datos.args.vs=== 'undefined') datos.args.vs= context.state.G.vs;

            console.log('**** ARGS ajaxRequest (', datos.args.accion, ') ****************************');
            context.state.ac_conexiones++;

            let url=(srv==1 ? context.state.G.informesURL : context.state.G.enrutadorURL);
            //console.log('llamada AJAX:  ', srv, datos.args, datos);

            return Axios.post(url, datos.args, {
                // return Axios.post(context.state.G, datos.args, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(result => {

                if (!result) {
                    // muestro mensaje de error
                    context.state.ac_conexiones--;
                    return { sql:{ error:true, msg:'Error conexion (100)' }};
                }

                // Pendiente: Crear control de errores
                if (typeof result.data.sql!== "undefined") {
                    switch (result.data.sql.msg) {

                    case "Sesión/Aplicación errónea":
                    case "Aplicación/Sesión errónea":
                    case "Token/Sesion Inválido":

                        // desconectamos
                        context.commit('logout');
                        // login
                        router.push({ path: 'login' });

                        // muestro mensaje de error
                        //this.$root.$alert.open(result.sql.msg, 'error');
                        alert('Ha superado el tiempo de Inactividad\nLa Sesión Actual se ha cerrado, debe de loguearse de nuevo.');


                        // recargamos página
                        location.reload();
                        location.reload();

                        break;
                    }
                }

                // muestro mensaje de error
                context.state.ac_conexiones--;

                console.log("result AJAXREQUEST ==========================================", result)
                return result.data;
            })

            .catch (error=> {
                // error en la recuperación de datos
                console.log("ERROR EN AJAXREQUEST ==========================================",error)
                alert("Error de conexion\n"+error);
                //router.push({ path: '/' });
                location.reload();
                return false;
            });
        },

        // obtengo schema
        // data: { tipo: tipo de componente (F,M,...), api:nombre de la API, fn_args:argumentos }
        async get_schema(context, modulo) {
            if (!context.state[modulo].api) return;

            // compruebo si existe el schema en el store
            if (context.state.schemas[context.state[modulo].tipo][context.state[modulo].api]) {
                context.commit("data2State", { modulo: modulo, prop: 'schema', value: JSON.parse(JSON.stringify(context.state.schemas[context.state[modulo].tipo][context.state[modulo].api])) });
                console.log('Obtengo schema del store (', context.state[modulo].tipo, ' - ', context.state[modulo].api, ')');
                return;
            }

            // ejecuto API
            let args = { tipo: context.state[modulo].tipo, api: context.state[modulo].api, accion: "get_schema", fn_args: null };

            console.log("----------------AWAIT SCHEMA ", args);
            let apiResult= await context.dispatch("ajaxRequest", { args: args });
            if (typeof apiResult=== "string") apiResult = JSON.parse(apiResult);
            if (apiResult.sql && apiResult.sql.error) return apiResult;
            console.log("----------------AWAIT SCHEMA FIN",context.state[modulo].api, apiResult);

            // transformo ctrls del formato antiguo al nuevo si así se indica con la variable NV=true
            apiResult.ctrls= await context.dispatch("schemaFormat", apiResult.ctrls);

            // guardo el schema en el store del Módulo
            context.commit("data2State", { modulo: modulo, prop: 'schema', value: apiResult });

            // guardo el schema en el store root
            context.commit("set_schemas", { tipo: context.state[modulo].tipo, prop: context.state[modulo].api, value: JSON.parse(JSON.stringify(apiResult)) });

        },


        // TEMPORAL
        // transformo ctrls del formato antiguo al nuevo si así se indica con la variable NV=true
        async schemaFormat(context, ctrls) {
            let ctrl= {};
            Object.keys(ctrls).forEach(key => {
                ctrl= ctrls[key];

                if (typeof ctrl.NV=== 'undefined') return;

                // tiene un schema de NUEVO FORMATO
                // compruebo si el nuevo schema es para la app en la que estoy
                console.log('NUEVO SCHEMA: ', ctrl.NV, context.state.G.APPNAME);
                if (ctrl.NV[3].indexOf(context.state.G.APPNAME) > -1) {
                    // si es asi, eliminio el antiguo schema del control y lo actualizo con el nuevo
                    // si no, lo dejo con el antiguo
                    ctrls[key]= ctrl.NV;
                }

            });

            return ctrls;
        },


        // envio SMS
        async envio_SMS(context, data) {
            let param= { apiArg:["", "envioSMS", {}, "aplica"], args:data};
            let apiResult = await context.dispatch("apiCall", param);
            console.log('result envio sms: ', apiResult);
            return apiResult;
        },


        // NUEVA VERSION
        async schGet(context, { api, storeName, schAPI }) {

            // compruebo si existe el schema en el store
            if (context.state.schemas[api]) {
                context.commit(storeName + "/SCHSET", JSON.parse(JSON.stringify(context.state.schemas[api])) );
                console.log('Obtengo schema del store (', context.state.schemas[api], ')');
                return;
            }

            // call API
            let apiResult= await context.dispatch('apiCall', { apiArg:schAPI, args:{} });
            console.log('schGet:: ', apiResult);
            // Pendiente: comprobar error

            context.commit(storeName + "/SCHSET", JSON.parse(JSON.stringify(apiResult.r)));
            context.commit("SCHSET", { prop: api, value: JSON.parse(JSON.stringify(apiResult.r)) });
        },

        // NUEVA VERSION
        async apiCall(context, data) {
            // defino la estructura de la llamada a la API con los datos recibidos
            let args= await context.dispatch("apiCallComposicion", data);

            // ejecuto llamada API y devuelvo el resultado
            return context.dispatch("ajaxRequest", { args });
        },

        // creamos estructura llamada API
        apiCallComposicion(context, data) {
            let args= { ...data.apiArg[2], ...data.args };
            return {
                app: 'vc',
                tipo: data.apiArg[0],
                api: data.apiArg[3]? data.apiArg[3] : '',
                accion: data.apiArg[1],
                args,
                version: context.state.G.VERSION
            }
        }
    }
});
