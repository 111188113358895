// AS3
//---------------------- CREAR AVISOS CA24
// function crear_avisoCa24(_t:String=''):void {
//     if (_t=='') return;
//     _t=_t.split('"').join("'"); 			//quitamos dobles comillas

//     var arg:String=db+'|'+'insert into exp_avisos (exp_id,tipo,fh,std,zona_id,apd_id,obs) values '+
//         '('+elem.id+','+(elem.chkconf24==1?'2':'1')+',now(),0,"'+_G.zona+'","'+_G.apd_id+'","'+_t+'")'; 		//trace('*crear_avisoCa24',arg);

//     _G.DBg.SQL_nostop(arg,leido,'INSERT');		//DBg.SQL(arg,leido,"INSERT");
//     function leido(r:*) {
//         if (r.st=="error") _root.Alerta("AvisoCa24: "+r.result); 	//else leerExped();
//     }
// }-----------------------------------------------------------------------------------------------------

// funescom -> expediente -> avisos
// fnt:        num, get, set


// tip:         *get      1=avisos incidencias,2=recep.documentos
// std:        *get      0,1 Pendientes, 2=Vistos
// id:           *set       Id del aviso
// txt:         *set       Texto respuesta observaciones
// env:       *set       Envio Respuesta 0=no,1=email,2=sms
// ema       *set       Email para envio
// sms        *set       Telefono para sms




export var mixinAvisos = {
  methods: {
    avisoCrear: function (tipo, dato) {
      if (!dato) return dato;
      switch (tipo) {
        case "obs":
          return this.avisoObs(dato)
      }
      return
    },
    //  avisoGet(tipo, dato) {
    async avisoGet(tipo) {
      console.log("aviso get:", tipo)
      //tipo: 1=avisos incidencias,2=recep.documentos
      let args = ''


      switch (tipo) {
        case "num":
          // args={ accion:'avisos',fnt:'num', tip:dato.tip}
          args = { accion: 'avisos', fnt: 'num', tip:1 }
          return this.avisoAjax(args)

      }
      return false;
    },
    async avisoAjax(args) {
      console.log("aviso login:", this.$store.state.login_estado)

      let llamada = {
        tipo: 'fnc',
        accion: 'expediente',
        fn_args: args
      }


      console.log("----------------AWAIT  aviso ", llamada);
      let apiResult = await this.$store.dispatch("ajaxRequest", { args: llamada });
      console.log("----------------AWAIT aviso result", apiResult);
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error')
        return false
      }
      return apiResult.r[0];
    },
    async avisoObs(dato) {
      let args = {
        tipo: "fnc",
        accion: "expediente",
        fn_args: {
          accion: "avisos",
          fnt: "aviso",
          exp_id: dato.expId,
          txt: dato.obs,
        },
      };
      console.log("----------------AWAIT  aviso ", args);
      let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
      console.log("----------------AWAIT aviso result", apiResult);
      if (apiResult.sql.error) {
        this.$root.$alert.open(apiResult.sql.msg, 'error');
        return;
      }
      return apiResult;

    }

  }
};
