export default [
  {
    path: "/",
    name: "Root",
    props: true,
    params: {
      param1: "root1"
    },
    view: "root"
  },

  {
    path: "/login",
    name: "login",
    props: false,
    params: {},
    view: "login"
  },

  {
    path: "/usuarios/",
    name: "usuM",
    props: false,
    params: {},
    view: "usuF"
  },

  {
    path: "/roles/",
    name: "rolesM",
    props: false,
    params: {},
    view: "rolesF"
  },

  {
    path: "/permisos/",
    name: "permisos",
    props: true,
    params: {
      param1: "permisos1"
    },
    view: "permisos"
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: "/cuentas/",
    name: "ctasM",
    props: false,
    params: {},
    view: "ctasF"
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: "/informes_castellon/",
    name: "castellonR",
    props: false,
    params: {},
    view: "castellonR"
  },
  {
    path: "/informes_fondos/",
    name: "fondosR",
    props: false,
    params: {},
    view: "fondosR"
  },






  //////////////////////////////////////////////////////////////////////////////////////////////////////////////





  {
    path: "/funerarias_sucursales/",
    name: "funes_M",
    props: false,
    params: {},
    view: "funes_F"
  },

  {
    path: "/agenda/",
    name: "agenda",
    props: false,
    params: {},
    view: "agenda"
  },

  {
    path: "/visados/",
    name: "visados_M",
    props: false,
    params: {},
    view: "visados"
  },

  {
    path: "/cobros_pagos/",
    name: "cobros_pagos_M",
    props: false,
    params: {},
    view: "cobros_pagos_F"
  },

  {
    path: "/cias_centrales/",
    name: "ciac_M",
    props: false,
    params: {},
    view: "ciacF"
  },

  {
    path: "/cias_sucursales/",
    name: "cias_M",
    props: false,
    params: {},
    view: "ciasF"
  },

  {
    path: "/miscelanea/",
    name: "misc_M",
    props: false,
    params: {},
    view: "miscF"
  },

  {
    path: "/expedientes/",
    name: "exp_M",
    props: false,
    params: {},
    view: "exp_F"
  },

  {
    path: "/coordinadores/",
    name: "jefeszona_M",
    props: false,
    params: {},
    view: "jefeszonaF"
  },

  {
    path: "/apds/",
    name: "apd_M",
    props: false,
    params: {},
    view: "apdF"
  },

  {
    path: "/cementerios/",
    name: "cemen_M",
    props: false,
    params: {},
    view: "cemenF"
  },

  {
    path: "/hornos/",
    name: "hornos_M",
    props: false,
    params: {},
    view: "hornos_FM"
  },

  {
    path: "/centros_territoriales/",
    name: "ct_M",
    props: false,
    params: {},
    view: "ct_FM"
  },

  {
    path: "/agencias_afectas/",
    name: "agen_M",
    props: false,
    params: {},
    view: "agenF"
  },

  {
    path: "/asignacion_documentos/",
    name: "tipoDocs",
    props: false,
    params: {},
    view: "ges_tipoDocs"
  },

  {
    path: "/tipo_documentos/",
    name: "tipoDocs_M",
    props: false,
    params: {},
    view: "tipo_documentos"
  },

  {
    path: "/claves_productos/",
    name: "clavesProd_M",
    props: false,
    params: {},
    view: "claves_productos"
  },

  {
    path: "/productos/",
    name: "productos_M",
    props: false,
    params: {},
    view: "productos"
  },

  {
    path: "/fras_expedientes/",
    name: "fras_Exp_M",
    props: false,
    params: {},
    view: "fras_Exp_F"
  },

  {
    path: "/fras_expedientesNV/",
    name: "frasExpF",
    props: false,
    params: {},
    view: "frasExpF"
  },

  {
    path: "/fras_libres/",
    name: "fras_libres_M",
    props: false,
    params: {},
    view: "fras_libres_F"
  },

  {
    path: "/mto/:mto",
    name: "Mto",
    props: true,
    params: {
      mto: ""
    },
    view: "mto"
  },


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: "/report_apds/",
    name: "apds_Report_M",
    props: false,
    params: {},
    view: "apdsR"
  },

  {
    path: "/report_central/",
    name: "central_Report_M",
    props: false,
    params: {},
    view: "centralR"
  },

  {
    path: "/report_expedientes/",
    name: "exp_Report_M",
    props: false,
    params: {},
    //view: "expR"
    view: "exp_Report_F"
  },

  {
    path: "/report_fras/",
    name: "fras_Report_M",
    props: false,
    params: {},
    view: "fras_Report_F"
  },

  {
    path: "/report_fun_sucursales/",
    name: "funes_Report_M",
    props: false,
    params: {},
    view: "funesR"
  },

  {
    path: "/report_miscelanea/",
    name: "misc_Report_M",
    props: false,
    params: {},
    view: "miscR"
  },

  {
    path: "/report_presupuestos/",
    name: "pres_Report_M",
    props: false,
    params: {},
    view: "pres_Report_F"
  },

  //////////////////////////////////////////////////////////////////////////////////////////////////////

  {
    path: "/apds_liquidacion/",
    name: "apds_liquidacion_M",
    props: false,
    params: {},
    view: "apds_liquidacion_F"
  },

  {
    path: "/apds_liquidacion_extras/",
    name: "apdsLiqExtrasM",
    props: false,
    params: {},
    view: "apdsLiqExtrasF"
  },

  {
    path: "/remesas_pagos/",
    name: "cobros_pagos_remesas_M",
    props: false,
    params: {},
    view: "cobros_pagos_remesas"
  },

  {
    path: "/grupos/",
    name: "grup_M",
    props: false,
    params: {},
    view: "gruposF"
  },

  {
    path: "/user_password/",
    name: "passChange",
    props: false,
    params: {},
    view: "passChange"
  },

  {
    path: "/ctw_descuadres/",
    name: "ctw_descuadres",
    props: false,
    params: {},
    view: "ctw_descuadres"
  },

  {
    path: "/mensajes_generales/",
    name: "msgGenM",
    props: false,
    params: {},
    view: "msgGenM"
  },

  {
    path: "/mensajes_particulares/",
    name: "msgPartF",
    props: false,
    params: {},
    view: "msgPartF"
  },


////////////////////////////////////////////////////////////////////////////////////////////////





  {
    path: "/usuweb/",
    name: "usuwebM",
    props: false,
    params: {},
    view: "usuwebF"
  },







];
