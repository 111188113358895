/* import * as validar from "@/components/validaciones.js"; */
export var mixSchValidate = {

  methods: {

 /*
       $ formatos varios  ( $cif, $dni, $tarjeta, $money)
       # maxleght|minlength
       @ max|min
       -###.##
       ? Chars (caracteres permitidos ej: ?HM, ?12 )
       req (required)

*/


    //  Chequear formato
    // let validar={
    //   req:false,
    //   formato:false,
    //   mask:false,
    //   enteros:false,
    //   decimales:false,
    //   negativo:false,
    //   maxlenght:false,
    //   minlenght:false,
    //   max:false,
    //   min:false,
    //   chars:false,
    //   Ucase:false,
    // };
    //


  schValidate(sch) {
    // Array de errores
    let arrErr=new Array;
    // schema recorrer
    for (const key in sch) {
        if (!sch[key][3] || sch[key][3].length===0) continue;

        console.log('')
        console.log('+------------validate field:', sch[key][0], sch[key][1]);
        console.log('|      propiedades:',sch[key][3].join(), ' // value:'+sch[key][2]);
        // recorrer propiedades de sch[key][3] donde estan todas las propiedades de validaciones
        for(var i=0, len=sch[key][3].length; i < len; i++){
          let err=this.checkErrors(sch[key],sch[key][3][i])
          if (err)  arrErr.push(err);
        }
        console.log('+-----------------------');
       //
    }
    // devuelve array con errores
    return arrErr;
  },

  // propiedades de validacion recorrer
  checkErrors(ct,prop){
    // sin elemento 3 o vacio  no comprobar
    // if (!prop||!prop.length) return false;
    //---------- required
    if (prop==='req'){
          if (ct[2]==='') return ct[1]+': Debe introducir un valor'
          return false;
    }

    //---------- Máximo/Mínimo
    if (prop.includes('@')){
        let maxMin= prop.slice(1).split("|");
        console.log('| @    max:', maxMin[0], 'min:',maxMin.length>1?maxMin[2]:'');
        if  (maxMin[0]!='' && maxMin[0]<ct[2])   return ct[1]+': Valor máximo: '+maxMin[0];
        if  (maxMin.length>1 && maxMin[1]>ct[2]) return ct[1]+': Valor mínimo: '+maxMin[1];
        return false;
    }

    //---------- maskNumeric máximo/mínimo
    if (prop.includes('#')){
        //validar primero númerico
        if (isNaN(ct[2])) return ct[1]+': Valor no numérico';
        let maxValue=this.maskMaxValue(prop);
        console.log('| '+prop+'     max segun mask:', maxValue);
        if (Math.abs(ct[2])>maxValue) return (ct[1]+': Valor máximo permitido '+maxValue);
        //if (!negativo && ct[2]<0)    return (ct[1]+': Valor negativo no permitido: ');
        return false;
    }


      //---------- Formato
      if (prop.includes('$')){
        switch (prop.slice(1)) {
          case 'money':
            // validar numérico
            if (isNaN(ct[2])) return ct[1]+': Valor no numérico';
            // validar valor máximo
            if (9999999.99<(Math.abs(ct[2]))) return ct[1]+': Valor máximo sobrepasado';
            // validar negativo
            break;
          case 'uCase':
            // en servidor guardar con mayusculas
            break;
          case 'cif':
            if  (ct[2] && !this.$validar.validaCif(ct[2])) return ct[1]+': Código de NIF/CIF no valido';
            break;
          case 'banco':
            if  (ct[2] && !this.$validar.validaBanco(ct[2])) return ct[1]+'Código de cuenta no valido';

          }
          return false;
        }

      //---------- Max/min characters
      if (prop.includes('%')){
        let maxMinLength= prop.slice(1).split("|");
        console.log('| '+prop+'     maxlenght:', maxMinLength[0], 'minlenght:',maxMinLength.length>1?maxMinLength[1]:'');
        if  (maxMinLength[0]<ct[2]) return ct[1]+': Máximo '+maxMinLength[0]+' caracteres';
        if  (maxMinLength.length>1 && maxMinLength[1]>ct[2]) return ct[1]+': Mínimo '+maxMinLength[1]+ ' caracteres';
        return false;
      }


    //----------Caracteres permitidos
    if (prop.includes('?')){
       let chars=prop.slice(1);
       let arrValues=ct[2].slice(1).split('');
       console.log('|      chars permitidos:', chars);

       for (var j=0, arrlen=arrValues.length; j < arrlen; j++){
          if (chars.includes(arrValues[j])===false) return 'Caracteres no permitidos en: ', ct[1], ' permitidos: ', chars;
       }
       return false;
    }
  },

    maskMaxValue(prop){
      let mask=prop.split('.');
      let negativo=mask[0].charAt(0)==='-'?true:false;
      let enteros=mask[0].length;
      if (negativo) enteros=enteros-1;
      let decimales=mask.length>1?mask[1].length:0;
      return Math.pow(10,enteros)-1+ (1-1/Math.pow(10,decimales));
    },

    ctProp(prop, ct) {
      //  componente

      if (prop === "comp") {
        if (!ct[4] || !ct[4].comp) return false;
        return ct[4].comp;
      }

      if (prop === "cget") {
        if (!ct[4] || !ct[4].cget) return false;
        return ct[4].cget;
      }

      //
      if (!ct[3]) return false;

      // formato
      if (prop == "format") {
        let f = ct[3].find(a => a.includes('$'));
        return f == undefined ? false : f.slice(1);
      }
      // Max/min caracteres
      if (prop == "maxlength") {
        let f = ct[3].find(a => a.includes('%'));
        return f ? f.split("|")[0].slice(1) : false;
      }
      if (prop == "minlength") {
        let f = ct[3].find(a => a.includes('%'));
        return f && f.split('|').length>1 ? f.split("|")[1] : false;
      }

      // Max/min valor
      if (prop == "max") {
        let f = ct[3].find(a => a.includes('@'));
        return f ? f.split("|")[0].slice(1) : false;
      }
      if (prop == "min") {
        let f = ct[3].find(a => a.includes('@'));
        return f ? f.split("|")[1] : false;
      }
      if (prop==='mask'){
        let f = ct[3].find(a => a.includes('#'));
        return f == undefined ? false : f;
        //return f ?f:false;
      }
      // propiedades varias
      if (ct[3].indexOf(prop) > -1) return true;
      return false;
    },
  }

}












