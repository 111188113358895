
<template>
    <div class="common_finder" v-if="modal">      
        <v-dialog
            v-model="modal" 
            content-class="modal"            
            persistent>
                <template>  
                    <keep-alive>                             
                        <component
                            :is="compName"    
                            :padre="padre"
                            :componenteTipo="componenteTipo"                             
                            @onEvent="event_F">            
                        </component>   
                    </keep-alive>         
                </template>
        </v-dialog> 
    </div>
</template>



<script>
  
    export default {
        data() {
            return {
                finderName:'',
                componenteTipo:'FC',
                modal:false,
                pool:[],               
                padre:''
            }
        },

        computed: {
            // cargo dinámicamente un componente finder
            compName() 
            {              
                if (!this.finderName) return;
                return () => this.$groute(`${ this.finderName}.vue`, "comp");
            }
        },

       
        methods: {
            // gestor de eventos del Finder
            event_F(evt) {
                console.log("DEV event_F ********** COMMON_FINDER", evt);

                switch (evt.accion) {                  

                    case "cerrar":                          
                        this.hide_finder();
                        break;

                    case "extraer":
                        this.pool[this.pool.length-1].schema.value= evt.row.id;
                        this.pool[this.pool.length-1].schema.comp.valueaux= evt.row.name;
                        
                        // si tiene fn, la ejecuto
                        if (this.pool[this.pool.length-1].fn) {                       
                            this.pool[this.pool.length-1].fn(evt.row);
                        } 
                        
                        this.hide_finder();                      
                        break;
                }                
            },


            // fn a la que llamamos desde fuera para abrir la ventana del finder
            open(finderName, schema=null, componenteTipo="FC", fn, padre) {
                console.log("DEV open " + finderName + " ********** COMMON_FINDER", componenteTipo);
              
                // añado finder a la cola de Finders
                this.pool.push({ finderName:finderName, schema:schema, componenteTipo:componenteTipo, fn:fn, padre:padre});

                // abro Finder
                this.show_finder(this.pool[this.pool.length -1]);                
            },


            // muestro Finder
            show_finder(data) {                
                this.finderName= data.finderName;      
                this.schema= data.schema;             
                this.componenteTipo= data.componenteTipo;              
                this.padre= data.padre;
                this.modal= true;
            },


            // cierro Finder
            hide_finder() {                
                this.pool.pop();

                if (this.pool.length> 0) {                                       
                    this.show_finder(this.pool[this.pool.length -1]);
                    return;
                }

                this.modal = false;
            }

        }
    };
</script>
