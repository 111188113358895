/** Vue Router **/

// Lib imports
import Vue from "vue";
import Router from "vue-router";
import plugs from "./general_plugs";

import { publicPath } from '../../vue.config'

// monto objeto de rutas
import paths from "./router_paths";
function route(path, view, name, params, props) {
  return {
    name: name || view,
    path: path,
    props: props,
    params: params,
    component: resolve => plugs.groute(`${view}.vue`, "comp").then(resolve)
  };
}

Vue.use(Router);

// creo un nuevo router
const router = new Router({
  base: publicPath,
  mode: "history",
  routes: paths
    .map(path => {
      return route(path.path, path.view, path.name, path.params, path.props);
    })
    .concat([{ path: "*", redirect: "/" }]),

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

export default router;
