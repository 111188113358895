
import { mixCt } from "@/mixins/mixCt";

export var mixCF = {
  mixins: [mixCt],
    
  data() {
    return {
      raiz:'',
      storeName:'',
      ct:0,
      pool:[],      
      compActual:null,
      modal:false,
      viewMtoOnly:false    
    };
  },


    

  //
  methods: {

    // añado componente a la cola de componentes, 
    // guardo el control recibido como componente actual
    // y muestro la ventana  
    // data: { ct, componenteName, fn= null, accion, store, recordField }  
    show(data) {
      // guardo componente recibido como componente actual
      this.compActual= data;

      // establezco la ruta raiz que le voy a pasar al componente a mostrar      
      this.raiz= data.store || 'commonF';

      // añado componente a la cola de componentes y abro ventana modal
      this.pool.push(data);      
      this.modal= true;
    },


    // elimino el último elemento de la cola y asigno como 
    // componente actual el siguiente de la cola.
    // si no hay ninguno en la cola, cierro ventana
    hide(evt) {
      
      
      if (evt.origen=='MtoView') {
        this.pool= [];
        this.compActual= null;

      }else {

        if (!evt.origen || evt.origen!='Mto') {
          this.pool.pop();
          this.compActual= this.pool[this.pool.length - 1];
        }

      }
           
      if (this.pool.length<= 0) this.modal= false;
    },


    // 
    extraer(item) {  
      console.log('extraer;; ', item, this.compActual);  

      // obtengo fn a ejecutar tras el extraer
      // si hemos definidos una, se ejecuta
      this.$set(this.compActual.ct, 2, item.id);
      this.$set(this.compActual.ct[4].comp, 'valueAux', item[this.compActual.ct[4].comp.fAux]);        
      this.$set(this.compActual.ct[4].comp, 'record', item);
      
      let fn= this.pool[this.pool.length - 1].fn;
      if (typeof fn=== 'function') fn(item);     
    },


    // gestor de eventos del Finder
    eventF(evt) {
      console.log("DEV eventF ********** COMMONF", evt);

      // si el evento recibido es el de cerrar, ejecuto función hide()
      // para eliminar el último componente de la cola y asignar el siguiente
      // como componente actual.
      // si hay otro componente en la cola, lo muestro 
      // si NO hay otro componente en la cola, cierro ventana 
      if (evt.accion== 'cerrar' || evt.accion== 6) {   
          this.hide(evt);                    
          return;
      }

      // guarda ID y nombre a mostrar en el ctrlF
      // el campo a mostrar en el ctrlF está definido en el schema en la prop fAux
      this.extraer(evt.item);
      
      //
      this.hide(evt);
    },


  },


  //
  computed: {

    // Mantenimiento o finder dependiendo de  
    compName() {         
      if (!this.compActual) return '';  
      if (!this.compActual.componenteName) return '';      
      return () => this.$groute(`${this.compActual.componenteName}.vue`, "comp");    
    },

    
    // devuelvo tipo de finder a mostrar
    tipoView() {
      if (!this.compActual) return 'FC';
      return this.compActual.ct[4].comp.tipoView;
    },


    // devuelvo nombre mto view a mostrar
    mView() {
      if (!this.compActual) return '';
      return this.compActual.ct[4].comp.mView;
    },


    // 
    ctrlCt() {
      if (!this.compActual) return '';
      return this.compActual.ct;
    },


    // accion a ejecutar en el componente a mostrar
    syncAccion() {      
      if (!this.compActual || !this.compActual.accion) return '';
      return this.compActual.accion;
    },


    // campo relacionado con el record del Maestro
    masterRecordField() {      
      if (!this.compActual || !this.compActual.recordField) return undefined;
      return this.compActual.recordField;
    },   

  },
  

}
