
export var validaciones = {

  // función de validación de CIF, DNI, NIE,...
  validaCif(cif) {

    if (cif.charAt(0)=='*') return cif;
    cif = cif.toUpperCase().replace(/[_\W\s]+/g, '');

    //nie/dni
    if(/^(\d|[XYZ])\d{7}[A-Z]$/.test(cif)) {
      let num = cif.match(/\d+/);
      num = (cif[0]!='Z'? cif[0]!='Y'? 0: 1: 2) + num;
      if(cif[8]=='TRWAGMYFPDXBNJZSQVHLCKE'[num%23]) {
          //return /^\d/.test(cif)? 'DNI': 'NIE';
          return cif;
      }
    }
    //sociedad
    else if(/^[ABCDEFGHJKLMNPQRSUVW]\d{7}[\dA-J]$/.test(cif)) {
      //return this.isValidCif(cif);
      return (this.isValidCif(cif) ? cif : false);
    }

    return false;
  },

  // sociedades
  isValidCif(cif) {
    var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var i;
    var digit;

    if (!letter.match(/[A-Z]/)) return false;

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);
      if (isNaN(digit)) return false;
      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) digit = parseInt(digit / 10) + (digit % 10);
        sum += digit;
      } else {
        sum += digit;
      }
    }
    sum %= 10;
    digit=(sum!==0 ? 10-sum : sum);

    if (letter.match(/[ABEH]/))   return String(digit) === control;
    if (letter.match(/[NPQRSW]/)) return letters[digit] === control;

    return String(digit) === control || letters[digit] === control;
  },


  // función de validación de cuenta bancaria
  validaBanco(cta) {
    if (cta.charAt(0)=='*') return cta;
    cta = cta.toUpperCase().replace(/[_\W\s]+/g, '');
    //cta = cta.toUpperCase().replace(/[^A-Z0-9]/g,'');

    let cc=cta.substr(-20);

    //validamos dc
    if (cta.length>=20) {
      let ban=cc.substr(0,4);
      let suc=cc.substr(4,8);
      let dc=cc.substr(8,10);
      let ccc =cc.substr(-10);

      var V=[1,2,4,8,5,10,9,7,3,6];

      //cuenta
      let control = 0;
      for (var i=0; i<=9; i++) control += parseInt(ccc.charAt(i)) * V[i];
      control = 11 - (control % 11);
      control=(control==11?0:(control==10?1:control));
      if (control!=parseInt(dc.charAt(1))) return false;
      //bancoSucursal
      control=0;
      var zbs="00"+ban+suc;
      for (i=0; i<=9; i++) control += parseInt(zbs.charAt(i)) * V[i];
      control = 11 - (control % 11);
      control=(control==11?0:(control==10?1:control));
      if (control!=parseInt(dc.charAt(0))) return false;
    }

    //añadimos IBAN sino lo tiene
    if (cta.length==20) {
      cta='ES' + this.calculaIBAN(cc) + cc;
    }

    //validamos iban
    if (cta.length==24) {
      if (this.calculaIBAN(cta) != cta.substr(2,2)) return false;
    }  else
      return false;

    return cta; //true;
  },

  calculaIBAN(cta) {
    let pais=(cta.length==20?'ES':cta.substr(0,2));
    let cc=cta.substr(-20);
    let dividendo = cc + (Number(pais.charCodeAt(0)-55)) + (Number(pais.charCodeAt(1)-55)) + '00';
    var DC = 98-modulo(dividendo, 97);
    return String(DC).padStart(2,'0');

    function modulo(valor, divisor) {
      var resto=0;
      var dividendo=0;
      for (var i=0;i<valor.length;i+=10) {
        dividendo = resto + "" + valor.substr(i, 10);
        resto = dividendo % divisor;
      }
      return resto;
    }
  }

}