export var mixInfoStores = {
  methods: {
    check(obj, propiedad) {
      if (obj && obj[propiedad]) return obj[propiedad];
      return "*";
    },

    infoCabecera(modulo, nivel) {
      if (!this.$store.state[modulo]) return '';

      let state = this.$store.state[modulo];
      // let getters=this.$store.getters;
      let s = '';
      s+="<caption>"+ nivel +"</caption>"
  
      s += "<tr>";
      s += "<th colspan='3'>"+modulo+"</td>";
      s 
      s += "</tr>";
   

      s += "<tr>";
      s += "<td>API:</td>";
      s += "<td>" + state.api + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>perm/extra</td>";
      s += "<td>" + state.perm + "</td>";
      s += "<td>" + state.permExtra + "</td>";
      s += "</tr>";

      return s;
    },

    infoF(modulo) {
      if (!this.$store.state[modulo]) return '';

      let state = this.$store.state[modulo];
      let s = "";
      s += "<tr>";
      s += "<td>accion</td>";
      s += "<td>" + state.accion + "</td>";
      s += "<td>&nbsp;</td>";
      s += "</tr>";
     
      s += "<tr>";
      s += "<td>accionCt</td>";
      s += "<td>" + JSON.stringify(state.accionCt) + "</td>";
      s += "<td></td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>N.records</td>";
      s += "<td>" + state.records.length + "</td>";
      s += "<td></td>";
      s += "</tr>";
      s += "<tr>";

      s += "<td>recordsSelected.id</td>";
      s += "<td>" + (!Object.keys(state.recordsSelected).length?'':state.recordsSelected[0].id) + "</td>";      
      s += "<td></td>";
      s += "</tr>";
      return s
    },

    infoM(modulo) {
      if (!this.$store.state[modulo]) return '';

      let state = this.$store.state[modulo];
      let s = "";
      s += "<tr>";
      s += "<td>accion</td>";
      s += "<td>" + state.accion + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>estado</td>";
      s += "<td>" + state.estado + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>ID</td>";
      s += "<td>" + state.ID + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>recordAccess</td>";
      s += "<td>" + state.recordAccess + "</td>";
      s += "<td></td>";
      s += "</tr>";

      return s;
    },

    infoMD(modulo) {
      if (!this.$store.state[modulo]) return '';

      let state = this.$store.state[modulo];
      let s = "";
      s += "<tr>";
      s += "<td>accion</td>";
      s += "<td>" + state.accion + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>accion</td>";
      s += "<td>" + state.accion1 + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>estado</td>";
      s += "<td>" + state.estado + "</td>";
      s += "<td></td>";
      s += "</tr>";


      s += "<tr>";
      s += "<td>estado</td>";
      s += "<td>" + state.estado1 + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>ID</td>";
      s += "<td>" + state.ID + "</td>";
      s += "<td></td>";
      s += "</tr>";

      s += "<tr>";
      s += "<td>ID</td>";
      s += "<td>" + state.ID1 + "</td>";
      s += "<td></td>";
      s += "</tr>";

     
      return s;
    },

    infoComun(modulo){
      // let state = this.$store.state[modulo];
      let getters = this.$store.getters;
      let s = "";
      s += "<tr>";
      s += "<td>syncRecord.id</td>";
      s += "<td>" +this.check(getters[modulo+"/syncRecord"], 'id') + "</td>";
      s += "<td></td>";
      s += "</tr>";
      return s;
    },

    infoStore(modulo,nivel){
      if (!this.$store.state[modulo]) return '';

      let state = this.$store.state[modulo];
      // let getters = this.$store.getters;
      let s = "";
      s += this.infoCabecera(modulo,nivel);

      if (state.storeTipo == "F") {
        s += this.infoF(modulo,nivel);
      }
      if (state.storeTipo == "M") {
        s += this.infoM(modulo,nivel);
      }
      if (state.storeTipo == "MD") {
        s += this.infoMD(modulo,nivel);
      }
      return s;
    }
  },


  computed: {
    info() {
      // let state = this.$store.state[this.storeName];
      // let getters = this.$store.getters;
      // let stateM = this.$store.state[this.masterStore];
      let s = '';

      s += "<div class='infostore' style='display:flex;padding:5px'>";
      s += "<table>";

      // STORE PROPIO
      s+=this.infoStore(this.storeName,'STORE');
      s+=this.infoComun(this.storeName);
      s += "</table>";

      // STORE MASTER
      if (this.masterStore){
        s += "<table>";
        s+=this.infoStore(this.masterStore, 'MASTERSTORE');
        s+=this.infoComun(this.masterStore);
        s+= "</table>";
      }
      // s += "<tr>";
      // s += "<td>syncRecord.id</td>";
      // s += "<td>" +this.check(getters[this.storeName + "/syncRecord"], 'id') + "</td>";
      // s += "<td></td>";
      // s += "</tr>"

      s+= "</div>";
      return s;
    }
  }
};
