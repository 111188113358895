export var fnGen = {
  
    //redondeo controlando negativos 
    round(n, d=2) {                
        //si negativo pasamos a positivo para redondear al alza      
        let neg=(Number(n)<0);
        let imp=(neg?-Number(n):Number(n));
        //redondeamos
        let r=Number(Math.round(imp*Math.pow(10,d))/Math.pow(10,d).toFixed(d));      
        
        return (neg?-r:r);
    }

}