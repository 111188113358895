// Main.js

// imports generales
import App from "./App.vue"; // vue inicial
import Vue from "vue"; // plugin Vue
import vuetify from "./plugins/vuetify"; // plugin Vuetify
import VueRouter from "vue-router"; // plugin Vue-router
import { validaciones } from './clases/validaciones'; // validaciones
import { fnGen } from './clases/fnGen'; // funciones Genéricas

// imports particulares
import router from "./common/router"; // plugin router
import store from "./stores/store"; // store
import plugs from "./common/general_plugs"; // funciones generales
import control_style from "./common/control_style"; // configuración visual v-bind ctrls
import VueCkeditor from 'vue-ckeditor5';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
const options = {
  editors: {
    'document': DecoupledEditor
  },
  name: 'ckeditor'
}

import VirtualList from 'vue-virtual-scroll-list';
import Entorno from "./common/Entorno.js";


// import CSS
require ('./assets/css/materialdesignicons.css');

// componentes globales
Vue.component("v-input_n", () => import("./components/vinput_n.vue"));
Vue.component("vinput_cif", () => import("./components/vinput_cif.vue"));
Vue.component("vlabel", () => import("./components/vlabel.vue"));
Vue.component("vlinput", () => import("./components/vlinput.vue"));
Vue.component("vtlabel", () => import("./components/vtlabel.vue"));
Vue.component("vllabel", () => import("./components/vllabel.vue"));
Vue.component('virtual-list', VirtualList);

// nuevo componentes (NUEVA VERSION)
Vue.component("ctrlF", () => import("./components/ctrlF.vue"));
Vue.component("virtualView", () => import("./components/virtualView.vue"));
Vue.component("uiText", () => import("./components/uiText.vue"));
Vue.component("uiTf", () => import("./components/uiTf.vue"));
Vue.component("uiPass", () => import("./components/uiPass.vue"));
Vue.component("uiDate", () => import("./components/uiDate.vue"));
Vue.component("uiObs3", () => import("./components/uiObs3.vue"));
Vue.component("uiUrl", () => import("./components/uiUrl.vue"));
Vue.component("uiImg", () => import("./components/uiImg.vue"));
Vue.component("uiMultiple", () => import("./components/uiMultiple.vue"));
Vue.component("uiMultiFilter", () => import("./components/uiMultiFilter.vue"));
Vue.component("uiMultiFilterFechas", () => import("./components/uiMultiFilterFechas.vue"));


// Entorno
const cfg = require("./common/entorno.json");
Vue.prototype.$cfg = cfg;
Vue.prototype.$cfe = Entorno;
Vue.prototype.$validar= validaciones;
Vue.prototype.$fnGen= fnGen;


// Plugins globales
Vue.use(plugs);
Vue.use(VueRouter);
Vue.use(control_style);
Vue.use( VueCkeditor.plugin, options );
/* devConsole('main');

function devConsole(api, modulo, fn, obj) {
  console.log("DEV " + api + " ****** " + modulo + " -> " + fn + ": ", obj);
} */


// FILTROS GLOBALES /////////////////////////////////////////////////////////////////////////////////////////////////////////////
Vue.filter("num", (N, tipo = 1) => {
  if (!window.Intl) return N;
  if (isNaN(N))  { console.log('NAN ***************************** in filter ', N); N= 0; }
  if (tipo == 1)
    return Intl.NumberFormat("de-DE", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Number(N).toFixed(2));
  if (tipo == 2)
    return Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR"
    }).format(Number(N).toFixed(2));

  return Intl.NumberFormat().format(N).toString()+tipo;
});


Vue.filter("fecha", (N, tipo= "sql2visual") => {
  if (!window.Intl) return N;
  if (N== "" || N==null) return N;

  if (tipo== "sql2visual") {
    let p = N.split("-");

    if (p[1].length) p[1] = String(p[1].padStart(2, "0"));
    if (p[0].length) p[0] = String(p[0].padStart(2, "0"));
    return [p[2], p[1], p[0]].join('/');
  }

  if (tipo == "visual2sql") {
    let p = N.split(/\D/g);
    return [p[2], p[1], p[0]].join("-");
  }

  return N;
});


Vue.filter("fechahr", (N, tipo= "sql2visual") => {
  if (!window.Intl) return N;
  if (!N || N== 0) return N;

  if (tipo== "sql2visual") {
    let [sdate, stime] = N.split(" ");
    let p = sdate.split("-");
    let h = stime.split(":");

    return `${[p[2], p[1], p[0]].join('/')} ${[h[0], h[1]].join(':')}`;
  }

  if (tipo == "visual2sql") {
    let p = N.split(/\D/g);
    return ([p[2], p[1], p[0]].join("-") + " " + [p[3], p[4], p[5]].join(":"));
  }

  return N;
});

// Directivas

//Mayusculas -> sin valor o false no convierte
Vue.directive('uppercase', {
  bind(el,binding) {
    let OK=binding.value;
    if (OK==undefined||OK==null) OK=true;
    if (OK) {
      console.log('*directive.uppercase',OK,el,binding);
      el.addEventListener('keyup', () => {
        if (OK) el.value = el.value.toUpperCase();
      });
    }

  }
});

// mask input
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

// directiva para control v-simple-checkbox en el slot 'header.data-table-select' de 'baseGridMD'
// para select all / select none lineas Grid
Vue.directive('Ripple', {});


// NO mostrar consola en produccion (false)
Vue.config.productionTip = false;
Vue.config.devtools=false

// INSTANCIA VUE /////////////////////////////////////////////////////////////////////////////////////////////////////////////
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
