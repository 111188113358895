/* Datos por defecto */
const cfg= {
    btra:{
        std: {
            btns:[],
            class:"white--text",
            label:true,
            fab:true,
            elevation:0,
            color:"indigo lighten-4",
            "x-small":true,
            icons: {
                small:true,
                color:"blue"
            }
        },

        row: {
            class_btn:"",
            label:false,
            icon:true,
            class_label:"",
            icons: {
                small:true,
                dark:true,
                color:"blue"
            }
        }
    }

};


/* Configuración Standard */
const std= {
    default:cfg,

    headers: {
        finder: {
            header:true,
            closable:true,
            class: "indigo lighten-4 blue--text title pl-3 pr-2 d-flex justify-space-between align-center ",
            style: "height:50px",
            flat: true,
            dense: true
        },

        mto: {
            header:true,
            closable:true,
            class: "indigo lighten-4 blue--text title pl-3 pr-2 d-flex justify-space-between align-center ",
            style: "height:50px",
            flat: true,
            dense: true
        }
    },

    btra: {
        /* Botoneras de Mantemimiento */
        M: {
            design: JSON.parse(JSON.stringify(cfg.btra.std)),
            btnsAccion: [
                { "accion":6, "btn": "salir" },
                { "accion":0, "btn": "ver", "view":false },
                { "accion":1, "btn": "nuevo" },
                { "accion":2, "btn": "editar" },
                { "accion":3, "btn": "eliminar" },
                { "accion":4, "btn": "guardar" },
                { "accion":5, "btn": "cancelar" }
            ]

        },

        MX: {
            design: JSON.parse(JSON.stringify(cfg.btra.std)),
            btnsAccion: [
                { "accion":6, "btn": "salir", "view":false },
                { "accion":0, "btn": "ver", "view":false },
                { "accion":1, "btn": "nuevo", "view":false },
                { "accion":2, "btn": "editar" },
                { "accion":3, "btn": "eliminar" },
                { "accion":4, "btn": "guardar" },
                { "accion":5, "btn": "cancelar" }
            ]

        },


        GRID: {
            design: JSON.parse(JSON.stringify(cfg.btra.std)),
            btnsAccion: [
                { "accion":0, "btn": "ver", "view":false },
                { "accion":1, "btn": "nuevo" },
                { "accion":2, "btn": "editar", "view":false },
                { "accion":3, "btn": "eliminar", "view":false }
            ]

        },

        FINDER: {
            design: JSON.parse(JSON.stringify(cfg.btra.row)),
            btnsAccion: [
                { "accion":0, "btn": "ver" },
                { "accion":1, "btn": "nuevo", "view":false },
                { "accion":2, "btn": "editar", "view":false },
                { "accion":3, "btn": "eliminar", "view":false }
            ]

        },


        /* Botoneras Extra */
        EXTRA: {
            design: JSON.parse(JSON.stringify(cfg.btra.std)),
            btnsAccion: []
        },

        FC: {
            design: JSON.parse(JSON.stringify(cfg.btra.row)),
            btnsAccion: [
                { "accion":'extraer', "btn": "extraer" }
            ]
        }

    },


    btn: {

        cerrar:{
            fab: true,
            depressed:true,
            dark:true,
            "x-small": true,
            color:"rgba(240,255,255,0.5)",
            class:"blue--text",
            icono: "mdi-close"
        },

        busca: {
            fab: true,
            dark: true,
            depressed:true,
            "x-small": true,
            color: "indigo lighten-4",
            icono: "mdi-filter"
        },

        buscaTexto: {
            class: "ma-1 blue--text",
            color: "indigo lighten-4",
            small: true,
            icono: "mdi-filter"
        },

        general:{
            fab: true,
            small: true,
            color: "indigo lighten-4"
        },

        ver: {
            label:"Ver",
            icon:"mdi-eye",
            tooltip:"Ver"
        },

        nuevo: {
            label:"Nuevo",
            icon:"mdi-plus",
            tooltip:"Nuevo"
        },

        editar: {
            label:"Editar",
            icon:"mdi-pencil",
            tooltip:"Editar"
        },

        eliminar: {
            label:"Eliminar",
            icon:"mdi-delete",
            tooltip:"Eliminar"
        },

        guardar: {
            label:"Guardar",
            icon:"mdi-content-save",
            tooltip:"Guardar"
        },

        cancelar: {
            label:"Cancelar",
            icon:"mdi-close-circle",
            tooltip:"Cancelar"
        },

        salir: {
            label:"Salir",
            icon:"mdi-location-exit",
            tooltip:"Salir"
        },

        extraer: {
            label:"Extraer",
            icon:"mdi-application-import",
            tooltip:"Extraer"
        },

        contabilizar: {
            label:"Contabilizar",
            icon:"mdi-calculator-variant",
            tooltip:"Contabilizar"
        },

        imprimir: {
            label:"Imprimir",
            icon:"mdi-printer",
            tooltip:"Imprimir"
        },

        facturar: {
            label:"Facturar",
            icon:"mdi-beaker-check-outline",
            tooltip:"Facturar"
        },

        facturas: {
            label:"Facturas",
            icon:"mdi-receipt",
            tooltip:"FACTURAS"
        },

        pdf: {
            label:"Pdf",
            icon:"mdi-file-powerpoint",
            tooltip:"Ver Pdf"
        },

        excel: {
            label:"Excel",
            icon:"mdi-file-excel-outline",
            tooltip:"Ver Archivo en Excel"
        },

        doc: {
            label:"Documento",
            icon:"mdi-file-document-outline",
            tooltip:"Ver documento"
        },

        upload: {
            label:"Upload",
            icon:"mdi-file-upload-outline",
            tooltip:"Subir fichero"
        },

        contrato: {
            label:"Contrato",
            icon:"mdi-text-box-outline",
            tooltip:"Ver Contrato"
        },

        exp: {
            label:"Expedientes",
            icon:"mdi-clipboard-file-outline",
            tooltip:"Ver Expediente"
        },

        copiar: {
            label:"Copiar",
            icon:"mdi-content-copy",
            tooltip:"Copiar fila"
        },

        pegar: {
            label:"Pegar",
            icon:"mdi-content-paste",
            tooltip:"Pegar fila"
        },

        docs: {
            label:"Docs",
            icon:"mdi-text-box-multiple-outline",
            tooltip:"DOCUMENTOS"
        },

        expansibleClose: {
            label:"",
            icon:"mdi-arrow-up-circle-outline",
            color:"blue-grey",
            tooltip:"Abrir expansible"
        },

        expansibleOpen: {
            label:"",
            icon:"mdi-arrow-down-circle-outline",
            color:"blue-grey",
            tooltip:"Cerrar expansible"
        },

        reload: {
            label:"",
            icon:"mdi-reload",
            tooltip:"Recargo Finder"
        },

        refresh: {
            label:"",
            icon:"mdi-refresh",
            tooltip:"Recargo Finder"
        },

        poliza: {
            label:"Póliza",
            icon:"mdi-text-box-search-outline",
            tooltip:"Obtener póliza"
        },

        comunicados: {
            label:"Comunicados",
            icon:"mdi-nfc-tap",
            tooltip:"Comunicados"
        },

        sendFax: {
            label:"Fax",
            icon:"mdi-file-send-outline",
            tooltip:"Envio Fax"
        },

        sendEmail: {
            label:"Email",
            icon:"mdi-email-send-outline",
            tooltip:"Envio Email"
        },

        sms: {
            label:"Sms",
            icon:"mdi-cellphone-message",
            tooltip:"Envio SMS"
        },

        subida: {
            label:"",
            icon:"mdi-arrow-up-thick",
            tooltip:"Subida de archivos"
        },

        btn_facturacion_row: {
            label:"Facturación",
            icon:"mdi-alpha-f-circle-outline",
            tooltip:"Facturación"
        }



    },


    styles:{
        marco:{
                "class":"mb-2 mr-2 pa-2",
                "elevation":1
        },

        headersStyle:{
            "width": "auto",
            "class": "light-blue--text text--darken-4 "
        },

        badge: {
            "offset-x":"-2px",
            "offset-y":"-2px",
            color:"#88909c",
            bordered:false
        }
    },


}



export default std;