<!-- Alert -->

<template>
  <div class="alert" v-if="snackbar">
    <!-- Fondo pantalla -->
    <v-overlay z-index="999"  :value="snackbar">

      <v-snackbar
        v-model="snackbar"
        v-bind="tipo ? tipo.bind : ''"
        :vertical="tipo.name === 'confirm'">
        <!-- Todos los tipos distintos de confirm -->
        <template v-if="tipo.name !== 'confirm'">
          <div class="columna mx-2">
            <div style="display:flex;justify-content: space-between;align-items: center;">
              <div>
                <v-icon dark>{{ tipo.icon }}</v-icon>
                <span  style="margin-left:12px;font-size: 1rem;line-height: 1rem" v-html="mensaje"></span>
              </div>

              <v-btn
                class="black--text"
                color="white"
                icon
                icono="mdi-close-circle-outline"
                @click="snackbar = false">
                  <v-icon dark >{{ "mdi-close" }}</v-icon>
              </v-btn>
            </div>
          </div>
        </template>

        <!-- Tipo Confirm -->
        <template v-else>
          <div class="columna mx-2">
            <div style="display:flex">
              <v-icon dark class="pa-2">{{ tipo.icon }}</v-icon>
              <!-- <span class="body-1">{{ mensaje }}</span> -->

              <span class="ml-3" style="font-size: 1rem;line-height: 1rem"  v-html="mensaje"></span>

            </div>

            <div class="ma-3" v-if="btns== null">
              <v-row no-gutters class="justify-center">
                <v-btn
                  class="btn ma-2 black--text"
                  color="white"
                  icono="mdi-close-circle-outline"
                  @click.native="agree"
                >
                  <v-icon >{{ "mdi-check-circle-outline" }}</v-icon>
                  Aceptar
                </v-btn>

                <v-btn
                  class="btn ma-2 black--text"
                  color="white"
                  icono="close-circle-outline"
                  @click.native="cancel"
                >
                  <v-icon small>{{ "mdi-close-circle-outline" }}</v-icon>
                  Cancelar
                </v-btn>
              </v-row>
            </div>

            <div v-else style="display:flex;width:600px;justify-content:center;">
              <template>
                <div v-for="(btn, index) in btns"  :key="index">
                <v-btn
                  :key="index"
                  class="btn ma-1 black--text"
                  color="white"
                  icono="mdi-close-circle-outline"
                  prepend-icon="mdi-check-circle"
                  @click.native="agreeBtns(index)">
                    {{ btn }}
                </v-btn>
                </div>
              </template>

            </div>
          </div>
        </template>
      </v-snackbar>

    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      mensaje: "",
      tipo: null,
      btns: null,
      tipos: [
        {
          name: "success",
          icon: "mdi-check-circle-outline",
          bind: { color: "green", top: true }
        },
        {
          name: "info",
          icon: "mdi-information-outline",
          bind: { color: "orange", top: true }
        },
        {
          name: "error",
          icon: "mdi-alert-circle-outline",
          bind: { color: "red", top: true }
        },
        {
          name: "confirm",
          icon: "mdi-help-circle-outline",
          bind: { color: "cyan darken-2", top: true }
        },
        {
          name: "default",
          icon: "mdi-alert-decagram-outline",
          bind: { color: "cyan darken-2", top: true }
        }
      ],
      resolve: null,
      reject: null
    };
  },

  methods: {
    // muestro notificación
    open(mensaje, tipo = "default", timeout = null, btns= null, bind = null) {
      // salgo si no he recibido un mensaje a mostrar
      if (!mensaje) return;
      this.mensaje = mensaje;

      // establezo la configuración de v-bind
      if (bind) {
        this.tipo = this.tipos[4];
        this.tipo.bind = bind;
      } else {
        const elem = this.tipos.find(elem => elem.name === tipo);
        this.tipo = elem === undefined ? this.tipos[4] : elem;
      }

      // muestro la notificación
      this.snackbar = true;

      // array de botones en un confirm
      this.btns= btns;

      // establezo el temporizador para el cierre automático de la notificación
      if (tipo === "confirm") {
        // si es un mensaje tipo 'confirm' devuelvo una promesa
        this.tipo.bind.timeout = 0 ;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });

      }

      // temporizador o indifinido
      timeout ? setTimeout(this.close, timeout) : (this.tipo.bind.timeout = 0);


    },

    // acepto promesa
    agree() {
      this.resolve(true);
      this.snackbar = false;
    },

    // acepto promesa
    agreeBtns(r) {
      this.resolve(r);
      this.snackbar = false;
    },

    // NO acepto promesa
    cancel() {
      this.resolve(false);
      this.snackbar = false;
    },

    // cierro notificación
    close() {
      this.snackbar = false;
    }
  }
};
</script>
<style scoped>
  btn {
    font-size: 1.0rem;
    line-height: 1.0rem;
  }

</style>
```
