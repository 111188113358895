//
// STORE_INI.JS

export default {
  namespaced: true,
  state: {
    URL: "https://iberopa.com/",
    URLinformes: "https://iberopa.com",
    URLconsultas: "",
    //
    locales_iniciales: {
      filtroPresupuestos: [
        { id:"", label: "TODOS" },
        { id:"10,30,15,35", label: "INHUMACION A/INCINERACION A + LOCAL" },
        { id:"15,35", label: "INHUMACION LOCAL A /INCINERACION LOCAL A" },
        { id:"10", label: "INHUMACION   A" },
        { id:"30", label: "INCINERACION A" },
        { id:"50,51,52,61", label: "TRASLADO/RECEPCION" },
        { id:"16,17,36,37", label: "PRIMA UNICA/PUG INHUMACION/INCINERACION" },
        { id:"16,17", label: "PRIMA UNICA/PUG IHUMACION" },
        { id:"36,37", label: "PRIMA UNICA/PUG ICINERACION" },
        { id:"22", label: "MUSULMAN" },
        { id:"11", label: "INHUMACION   B" },
        { id:"31", label: "INCINERACION B" },
        { id:"12", label: "INHUMACION C" },
        { id:"50", label: "TRASLADO PROVINCIAL" },
        { id:"51", label: "TRASLADO NACIONAL" },
        { id:"52", label: "TRASLADO INTERNACIONAL" },
        { id:"61", label: "RECEPCIÓN/TERMINACIÓN" },
      ],

      rTribut:[
        {id:"0", name:"IVA",  tipos:[{value:"21.0",text:"21%"}, {value:"10.0",text:"10%"},{value:"0.0",text:"0%"},{value:"4.0",text:"4%"}]},
        {id:"1", name:"IGIC", tipos:[{value:'3.0',text:"3%"}, {value:'7.0',text:"7%"},{value:'0.0',text:'0%'},{value:'9.5',text:'9.5%'},{value:'13.5',text:'13.5%'},{value:'20.0',text:'20%'}]},
        {id:"2", name:"IPSI", tipos:[{value:'4.0',text:"4%"}, {value:'1.0',text:"1%"},{value:'2.0',text:'2%'},{value:'8.0',text:'8%'},{value:'10.0',text:'10%'},{value:'0.5',text:'0.5%'},{value:'0.0',text:'0.0%'}]},
        {id:"3", name:"IC",   tipos:[{value:'0.0',text:"0%"}]}
      ],

      iva10:[12,21,52,140],

      recordsCopy:[]
    },

  },

  getters: {},

  mutations: {},


  actions: {
    async load_dataIni(context) {

      // API de datos_iniciales
      let param= { apiArg:["", 'load', {}, "datosIniciales" ], args:{}};
      let apiResult = await context.dispatch("apiCall", param,  { root:true });
      console.log('DATOS INICIALES: ', apiResult);

      /* var args = { tipo: "schemas", api: "datos_iniciales", accion: "load" };
      var apiResult = await context.dispatch("ajaxRequest", { args: args }, { root: true });
      console.log('DATOS INICIALES: ', apiResult); */

      if (apiResult.sql.error) return apiResult;

      // mezclo los datos iniciales recibidos con los array declarados en 'locales_iniciales'
      apiResult.r[0]= { ...apiResult.r[0], ...context.state.locales_iniciales };

      // guardo los datos iniciales recibidos en el store
      context.commit("update_datosIniciales", apiResult.r, { root: true });
      return apiResult;

    }
  }
};
