import { mixFormato } from "@/mixins/mixFormato";
import { mixComponente } from "@/mixins/mixComponente";
import { mixSchValidate } from "@/mixins/mixSchValidate";
import { mixInfoStores } from "@/mixins/mixInfoStores";

export var mixCt = {
  mixins: [mixFormato, mixComponente, mixSchValidate, mixInfoStores],

  data() {
    return {
      app: this.$store.state.G.APP,
      rol: this.$store.state.G.ROL,
      rolId: this.$store.state.G.ROLID,
      usu: this.$store.state.G.USUARIO,
      zona: this.$store.state.G.ZONA,
      ndep:this.$store.state.G.USUDAT.ndep,

       // vars neesarias para headerSet
       // headerActual (nombre de cabecera actual)
      // headerGrid (Cabecera actual a mostrar con estilos añadidos)
      // headers (array de datos de cabecera posibles, definir en particular)
      headers:{},
      headerGrid:[],
      headerActual: '*',
   
    }
  },


  methods: {
    

    // paso valores del record a los controles
    async record2ctrl(record, ctrls, ini = false) {
      console.log("--- record2ctrl record", record);
      console.log("--- record2ctrl ctrls", ctrls);
      // recorro el schema y le asigno el valor del record al control
      //
      let ctrl;
      let sch= JSON.parse(JSON.stringify(this.sch));

      for (var key in ctrls) {
        ctrl = ctrls[key];

        // componente
        if (this.ctProp("comp", ctrl)) {
          // el componente es el responsable de meter en  el schema lo que sea necesario
          this[ctrl[4].comp.type]().set({ ctrl, ctrls, record, sch:ini? sch : null });
          continue;
        }
       
        // cget
        if (this.ctProp("cget", ctrl)) {       
          // el cget es responable de meter en  el schema lo que sea necesario 
          //return this.cget({ctrl, schema, record, ini})
          if (!this.$isFunction(this[ctrl[4].cget])) continue;
          this[ctrl[4].cget]({ctrl, ctrls, record});
          continue;
        }

        //------------------------------------------------------------------
        // casos de asignacion de valores de record a control ctrl[2]
        // 1- el control No tiene definido tablefield ctrl[0]=''
        //    si tiene    defaultValue ctrl[2] se deja como valor
        //    si no tiene defaultValue ctrl[2]='' se asigna cadena vacia

        // 2 - el record[ctrl[0]] no existe
        //       se aplica el caso 1
        //       (avisar por console.log por posible error, es raro este caso)
        this.$set(ctrl, 2, (ctrl[0] && record[ctrl[0]] ? record[ctrl[0]] : (ini? sch[key][2] : ctrl[2] || "")));

        // Paso dato formateado si tiene formato
        let formato = this.ctProp("$", ctrl);
        this.$set(ctrl, 2, formato ? this.sql2visual(formato, ctrl[2]) : ctrl[2]);
      }

      //-------------------------------------------------------------------
      //
      //  FALTA APLICAR FORMATOS

      // ctrl[2]= this.get_fieldValue({ctrl, schema, record, ini});
      // if (ctrl.format) ctrl.value= this.sql2visual(ctrl, ctrl.value);

      console.log("--- record2ctrl (Mixin) --- schema", ctrls);
    },
    /* async record2ctrl(record, ctrls, ini = false) {
      console.log("--- record2ctrl record", record);
      console.log("--- record2ctrl ctrls", ctrls);
      // recorro el schema y le asigno el valor del record al control
      //
      var ctrl;
      for (var key in ctrls) {
        ctrl = ctrls[key];

        // componente
        if (this.ctProp("comp", ctrl)) {
          // el componente es el responsable de meter en  el schema lo que sea necesario         
          this[ctrl[4].comp.type]().set({ ctrl, ctrls, record, ini });
          continue;
        }

        //------------------------------------------------------------------
        // casos de asignacion de valores de record a control ctrl[2]
        // 1- el control No tiene definido tablefield ctrl[0]=''
        //    si tiene    defaultValue ctrl[2] se deja como valor
        //    si no tiene defaultValue ctrl[2]='' se asigna cadena vacia

        // 2 - el record[ctrl[0]] no existe
        //       se aplica el caso 1
        //       (avisar por console.log por posible error, es raro este caso)
        ctrl[2] = ctrl[0] && record[ctrl[0]] ? record[ctrl[0]] : ctrl[2] || "";

        // Paso dato formateado si tiene formato
        let formato = this.ctProp("$", ctrl);
        ctrl[2] = formato ? this.sql2visual(formato, ctrl[2]) : ctrl[2];
      } 

      //-------------------------------------------------------------------
      //
      //  FALTA APLICAR FORMATOS

      // ctrl[2]= this.get_fieldValue({ctrl, schema, record, ini});
      // if (ctrl.format) ctrl.value= this.sql2visual(ctrl, ctrl.value);

      console.log("--- record2ctrl (Mixin) --- schema", ctrls);
    },*/

    // paso valores de los controles a un record y lo devuelvo
    // soloDirencias=true  devuelve solo los cambios
    // soloDirencias=false devuelve el record completo con cambios y sin cambios
    // nosave= true. Tiene en cuenta los cambios en los campos con propiedad nosave
    ctrl2record(ctrls, record, soloDiferencias = true, nosave = true) {
      console.log("--- ctrl2record (Mixin) ---", ctrls);
      //
      var record_aux = {};
      var ctrl;
      Object.keys(ctrls).forEach(key => {
        ctrl = ctrls[key];

        // si tiene la propiedad nosave no paso
        if (nosave && this.ctProp("nosave", ctrl)) return;
        
        // guardo el/los dato/s del componente en el record_aux
        if (this.ctProp("comp", ctrl)) {
          this[ctrl[4].comp.type]().get({ ctrl, ctrls, record, record_aux });
          return;
        }

        // si no tiene tableField no paso
        if (!ctrl[0]) return;

        // Paso dato formateado si tiene formato
        let formato = this.ctProp("$", ctrl);

        record_aux[ctrl[0]] = formato
          ? this.visual2sql(formato, ctrl[2])
          : ctrl[2];
      });

      console.log(
        "record_aux antes de data_update: ",
        JSON.parse(JSON.stringify(record_aux))
      );
      // comparo record_aux con record y devuelvo los campos que han cambiado
      if (soloDiferencias) {
        var data_update = {};
        Object.keys(record_aux).forEach(key => {
          if (record_aux[key] == "" && record[key] == undefined) return;
          if (record_aux[key] == undefined && record[key] == "") return;
          if (record_aux[key] != record[key]) {
            data_update[key] = record_aux[key];
          }
        });
        console.log(
          "record_aux despues de data_update: ",
          JSON.parse(JSON.stringify(data_update))
        );
        // devuelvo solo diferencias
        return data_update;
      }
      // devuelvo record completo
      return record_aux;
    },


    // monto nombre del store del componente que vamos a cargar.
    // recibo el objeto store a registrar.
    // formato: 
    //   store recibido como propiedad (storeRaiz) + 
    //   nombre store particular definido en el stIni (stIni.api) +     
    //   número de 2 dígitos para diferenciar el store de un componente que se ha cargado varias veces    
    registerStore(store) {
      let tmpName = this.storeRaiz + this.stIni.name;

      let modules = Object.keys(this.$store._modules.root._children);
      let n = 0, tmpN = 0;

      if (!this.storeRaiz) {        
        modules.forEach(elem => {
          if (elem.substr(0, tmpName.length) != tmpName) return;
          tmpN = Number(elem.slice(-2));
          if (tmpN > n) n = tmpN;
        });

        this.storeName = tmpName + String((n + 1)).padStart(2, "0");
        this.raiz = this.storeName;

      } else {        
        this.storeName = tmpName;
        this.raiz = this.storeRaiz;
      }

      if (!this.$store.state[this.storeName] && store) {
        this.$store.registerModule(this.storeName, store);
      }

    },


    async iniStore() {       
      this.stIni.pView= this.getPermisoModulo(this.stIni.mView);      
      await this.$store.dispatch(this.storeName + "/ini", { stIni: this.stIni, schAPI: this.apiArgs.sch, storeName: this.storeName });      
    },

    getPermisoModulo(modulo) {            
      if (typeof this.$store.state.G.PESO[modulo]=== 'undefined') {
        // pendient de ver valor en error
        alert ("error busqueda permiso: "+modulo);
        return {};
      }

      let permiso= this.$store.state.G.PESO[modulo];           
      return permiso
    },


    // nombre ruta completa store
    getStoreName(name) {
      return this.storeRaiz + name;
    },


    // Pendiente: comprobar
    
 
    headerSet(headerUpdate="buscar") {

      // headerActual (nombre de cabecera actual)
      // headerGrid (Cabecera actual a mostrar con estilos añadidos)
      // headers (array de datos de cabecera posibles
 
      if (this.headerActual== headerUpdate) return this.headerGrid;
      return this.headerSetData(headerUpdate, this.headers);
    },

    headerSetData(headerUpdate='', headersArray){
      // busco si la acción recibida está definida en alguna de las cabeceras del particular (this.headers)
      // si la encuentra, configura la cabecera con esos campos (definidos en fields)
      // si NO la encuentra, busca si la cabecera actual está definida en algunas de las cabeceras
      // si la encuentra, configura la cabecera con esos campos, si no, configura con la primera cabecera definida 
      let result= headersArray.filter(item=>item.name.includes(headerUpdate));      
      if (result.length) {
        result= result[0].fields;
        this.headerActual= headerUpdate;

      }else {        
        let resultActual= headersArray.filter(item=>item.name.includes(this.headerActual));      
        result= resultActual.length? resultActual[0].fields : headersArray[0].fields;
      }
            

      // Añadir al resultado el estilo por defecto this.$cfe.styles.headersStyle
      return result.map((item) => ({
        ...this.$cfe.styles.headersStyle,
        ...item
      }));

    },

    
    //redondeo controlando negativos 
    round(n, d=2) {                
      //si negativo pasamos a positivo para redondear al alza      
      let neg=(Number(n)<0);
      let imp=(neg?-Number(n):Number(n));
      //redondeamos
      let r=Number(Math.round(imp*Math.pow(10,d))/Math.pow(10,d).toFixed(d));
      
      return (neg?-r:r);
    },
    
    
    // compara records del Grid con los definidos en su store y
    // compruebo si hay lineas duplicadas
    checkDatosLineas(records) {

      // compara lineas records
      console.log('records check: ', records);
      for (let x=0; x< records.length; x++) {
        if (typeof records[x]=== 'undefined') return false;        
      }

      // comprueba si hay lineas duplicadas
      const busqueda= records.reduce((acc, elem) => {
        const clave = JSON.stringify({ prod_id: elem.prod_id, namea:elem.namea });
        acc[clave] = ++acc[clave] || 0;
        return acc;      
      }, {});
     
      const duplicados= records.filter((elem) => {
        return busqueda[JSON.stringify({ prod_id: elem.prod_id, namea:elem.namea })];
      });

      // existen lineas duplicadas
      if (duplicados.length) {
        this.$root.$alert.open('Error en lineas (Productos duplicados u erróneos)', 'error');
        return false;
      }

      return true;
    },

  }
};
