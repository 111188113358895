<template>
    <div class="loading">
        <v-overlay z-index="9999" :value="get_conexiones">
            <v-row justify="center" align="center">
                <div class="text-center ma-12">
                  {{ mensaje }}
                  <v-progress-circular
                      v-bind="bind">                    
                  </v-progress-circular>
                </div>
              </v-row>
        </v-overlay>      
    </div>
</template>



<script>
export default {
  props: {
    mensaje: { type: String, default: "Espere ... Procesando" },
    bind: { type: Object, default: function() { return this.$cfg.ctrls.loading; }}
  },

  computed: {

      // 
      get_conexiones:
      {
          get() {             
              return this.$store.state.ac_conexiones> 0? true : false;
          },

          set() {}
      }      
    
  },

};
</script>
