import vuetify from "@/plugins/vuetify";

const plugs = {
  // GET ROUTE

  // devuelvo la importación de un componente
  groute: function(nombre, tipo) {
    switch (tipo) {
      case "img":
        return require("@/assets/img/" + nombre);
      case "base":
        return import("@/base/" + nombre);
      case "comp":
        return import("@/components/" + nombre);
      case "view":
        return import("@/views/" + nombre);
      case "store":
        return import("@/stores/modulos/" + nombre);     
    }
  },

  //
  install(Vue) {
    // rutas
    vuetify,
      (Vue.prototype.$groute = (nombre, tipo) => {
        return plugs.groute(nombre, tipo);
      });

    Vue.prototype.$isFunction = functionToCheck => {
      return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === "[object Function]"
      );
    };
  }
};

export default plugs;
